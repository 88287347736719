import { gql } from 'apollo-boost';


export   default {
 GET_FOLDERS :gql`
 query Folders (   
  $sort: String , 
  $limit: Int,
  $filters: [Filter],
  $offset: Int,
  $search: String,
  $s: String,
  $refresh:String){ 
folders(
    refresh:$refresh
    sort:$sort,
    limit: $limit,
    filters: $filters,
    offset: $offset,
    search: $search,
    s: $s
    ) {
        _id
     name
     module
isDroppable
     owner
     group
     icon
     color
      items

}} `,

CREATE_FOLDER:gql`

mutation CREATE_FOLDER(  $name: String!, $group: String!, $module: String!  ){
folderCreate( name:$name , group:$group , module:$module){
    _id
    name
    module
    isDroppable
    owner
    group
    icon
    color
}}
`

,
REMOVE_FOLDER:gql`

mutation REMOVE_FOLDER(  $id: ID!  ){
folderRemove( id:$id ){
    _id
    name
    module
    isDroppable
    owner
    group
    icon
    color
}}
`,
UPDATE_FOLDER:gql`

mutation UPDATE_FOLDER( $id:ID!, $name: String, $items:[ID],$op:String ){
folderUpdate(id:$id , name:$name , items:$items , op:$op){
    _id
    name
    module
    isDroppable
    owner
    group
    icon
    color
    items
}}
`

,

GET_SETTINGS:gql`
query Settings (   
 $sort: String , 
 $limit: Int,
 $filters: [Filter],
 $offset: Int,
 $search: String,
 $s: String){ 
settings(
   sort:$sort,
   limit: $limit,
   filters: $filters,
   offset: $offset,
   search: $search,
   s: $s
   ) {
       target
nameSpace
type
data{attr value}
     

}} `

}


