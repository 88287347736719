import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const ModuleWrapper  = styled.div`
 cursor: pointer;
 width: 100%;
 height: 100%;
 padding:10px;

 display: table;
  span {float:right ;  height:30px;
    svg:hover{bakground:red;}

    
    }
}
 
 ` 