import React from 'react'
import { useDrop } from 'react-dnd'


export default  function Droppable({type, target,  style ,dropAction, children}) {
  const [{ canDrop, isOver }, drop] = useDrop({
    accept:type,
    drop: (item) => {dropAction(item , target)},
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  })
  const isActive = canDrop && isOver
  let backgroundColor = 'transparent' 
  if (isActive) {

    backgroundColor= "#ffffff8a";
      
  } 
  return (
    <span ref={drop} style={{ ...style, display:"block" , borderRadius:5 , width:"100%",  backgroundColor }}>
  {children}
    </span>
  )
}

