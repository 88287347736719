
import { gql } from 'apollo-boost';

import {name} from "#config"

const souscriptionDetails= ` 

srri
IBAN
dateComptable
dateComptableDay
dateComptableMonth
dateEffet
dateLiquidation
deductibiliteFiscale
dureeDeblocage
id
modeDeGestion
montantBrut
objectifPrincipal
periodeInformation
periodiciteVersement
provenanceFonds
reglement
signature
state
typeContrat
versementProgramme code FC
versementPeriodiqueBrut
allocation
updatedAt`

const offreList=`
code
id
state
souscriptions {code FC  objectifPrincipal typeContrat }
RBE
LM
createdAt
updatedAt
`

const itemDetails=`
code
id
state
souscriptions {${souscriptionDetails}}
RBE
LM
objectifPrincipal
contrat
updatedAt
`

const offreListLight=`
code
id
client{_id}
createdAt
souscriptions {createdAt montantBrut typeContrat }
RBE
LM
`



export   default {



GET_ONE: gql` query GET_OFFRE($id:ID!){ 
    offre( id:$id ) {
     ${itemDetails}
    }
}`,

GET_LIST :gql`
query GET_LIST($where:JSON , $sort:String ){ 
 ${name}s (where:$where , sort:$sort ){
    
     ${itemDetails}
     _id
}} `
,
GET_LIST_LIGHT :gql`
query GET_LIST_LIGHT($where:JSON){ 
   offres (where:$where ){
    
     ${offreListLight}
     _id
}} `,

CREATE_ONE: gql` 

mutation CREATE_ONE($input: createOffreInput) {
  createOffre(input: $input) 
    {
     offre {  ${itemDetails}}
    }
  }


  `,

  UPDATE_ONE: gql` 

mutation UPDATE_ONE($input: updateOffreInput) {
 updateOffre(input: $input) 
    {
     offre {  ${itemDetails}}
    }
  }


  `,

  DELETE_ONE: gql` 

mutation DELETE_OFFRE($input: deleteOffreInput) {
 deleteOffre(input: $input) 
    {
     offre {  ${itemDetails}}
    }
  }


  `,

  GET_SOUSCRIPTION: gql` query GET_SOUSCRIPTION($id:ID!){ 
    souscription( id:$id ) {
     ${souscriptionDetails}
    }
}`,



GET_SOUSCRIPTIONS :gql`
query GET_SOUSCRIPTIONS($where:JSON){ 
   souscriptions (where:$where ){
    
     ${souscriptionDetails}
     _id
}} `
,

CREATE_SOUSCRIPTION: gql` 

mutation CREATE_SOUSCRIPTION($input: createSouscriptionInput) {
  createSouscription(input: $input) 
    {
     souscription {  ${souscriptionDetails}}
    }
  }

`
,


GET_SIMULATIONS :gql`
query GET_SIMULATIONS($where:JSON){ 
   simulations (where:$where ){
    type
    riskProfile
    srri
   allocation
   allocationAV
   montantInitial
   versementPeriodique
   periode
   anneeDeblocageF
   anneeDeblocage
     _id
}} `



}
 
