import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from "../Tooltip"
import  {formatDate} from "@botndot/lib/helpers/dates"
import  {displayName, text_truncate} from "@botndot/lib/helpers/text"
import {TextWrapper} from "./Text.style"
import { injectIntl, FormattedMessage } from 'react-intl';

const InjectMassage = props => <FormattedMessage {...props} />;
const Translator= injectIntl (InjectMassage, {
  withRef: false,
});





function  renderer(content,options , ...props){
let className = "-"
const { type, currency, tooltip, length, withColor ,translate , link}=options||{}


if(_.isNil(content)) return <span >-</span> 

switch (type){
case "currency" : return new Intl.NumberFormat('fr-FR', { style: 'currency', currency , maximumFractionDigits:2 }).format(content);

case "number":{
    if(_.isNil(content)) return (<span >-</span> )
    let value=_.toNumber(content).toFixed(2)*1;
    if(withColor && value<0  ) className="negative";
    return   <span className={className} > {value}</span>  
}

case "%" : {
    if(_.isNil(content)) return (<span >-</span> )
    let value=(100*_.toNumber(content)).toFixed(2)*1;
    
    if(withColor && value<0  ) className="negative";
    
    
    return   <span className={className} > {value}%</span>  }
case "text" :{
    
    if(translate){ 
        
        
        return <Translator id={content} /> }
    
    let newContent=  text_truncate(content, length) ;
    if(tooltip  && newContent.length!=content.length){ return <Tooltip title={content}> {newContent}  </Tooltip>   }
    return newContent;

}
case "date" :  return (formatDate(content ,options.format , options.fromNow ))
case "name" :  return displayName(options.prenom , options.nom)

default : 
if(translate){ 
        

    return <Translator id={content} /> }
return _.capitalize(content.toString())
}



}

export default function Text({content,options , ...props}) {




    return (
<TextWrapper {...props}> { renderer(content, options)}</TextWrapper>
         
    )
}


export const  Montant=function({content,options ,...props}) {
    return (
      <Text  content={content}  {...props} options={{...options , type:"currency" , currency:"EUR"}} />
     )
  }

Text.defaultProps = {
    options:{}
    
}



