import {ContextMenuWrapper} from "./ContextMenu.style"
import React from 'react'
import ReactDOM from "react-dom"
import { ContextMenu as MEnu , MenuItem } from "react-contextmenu";

/**
 * Insert text at cursor position.
 *
 * @param {actions} [{  label , action:fn()}]
 * @public
 */
export default function ContextMenu({actions , id} ) {
    return ReactDOM.createPortal(
        <ContextMenuWrapper>

            <MEnu hideOnLeave={true} style={{ zIndex: 1000 }} id={id}>
   {             actions.map((menuItem , i)=>(
          
          <MenuItem  key={i} onClick={menuItem.action} >
                        {menuItem.label}
      </MenuItem>

   ))}
              
            </MEnu>
        </ContextMenuWrapper>,
        document.getElementById("root")
    );
}
