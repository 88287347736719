
import saMessages from '../locales/ar_SA.json';
import {addHeader} from "../helpers"
const saLang = {
  messages: {
    ...addHeader(saMessages),
  },

  locale: 'ar',
};
export default saLang;
