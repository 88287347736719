import React from 'react';
import { WrapperStyle } from './Wrapper.style';
import { Card } from '@botndot/ui';

export default function Wrapper({ title, actions, children }) {
  return (
    <WrapperStyle>
      <Card title={<h3>{title}</h3>} extra={actions}>
        {children}
      </Card>
    </WrapperStyle>
  );
}
