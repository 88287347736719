import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';

export default function Donut({value ,base,  height="100%" , title , colorsType}) {
const theme=useContext(ThemeContext)
const colors=theme.palette[colorsType||"defaultChart"]
const percVal=(value*100/base).toFixed(2)*1;
const restVale=(100-percVal).toFixed(2)*1;
const   option = {


    series: [
        {

            type: 'pie',
            radius: ['50%', '70%'],
            color:[colors[0], '#f3f3f3'],
            label: {
                show: true,
                position: 'center',
                   fontSize:20,
                    fontWeight: 'bold',
              formatter:()=>(percVal+"%")
                
            },
            emphasis: {
    
            },
            labelLine: {
                show: false
            },
            data: [
                {value: percVal, name: '' },
                {value: restVale, name: 'rest'},

            ]
        }
    ]
};
if(title) option.title=  [ {text:title,x: 'center', y: '84%', textStyle:{color:"#333",fontSize:"14px"}},];

    return (
        <ReactEcharts
        option={option}

        style={{height:  height, width: "100%"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}
