import React from 'react'
import {displayName} from "@botndot/lib/helpers/text"
import {getClient,getDispatch} from "#helpers/utility"
import {getIcon} from "@botndot/assets/Icons"
import   {Button}  from "@botndot/ui"
import {ModuleWrapper } from './MenuStyle'
export default function ClientMenu({...props}) {


    const client=getClient()
    const dispatch = getDispatch();

  return (
<ModuleWrapper  {...props}  >{(client && client.nom) ?( <div>{

displayName(client.prenom,client.nom)} <span onClick={()=>{ dispatch( "setSelectedScreen",{  screen: "List",  selectedView:{view: "Gallery",} } );}}>{getIcon("navigation.close.white")}</span></div>) :(<div>CLIENTS <span>{getIcon("navigation.bottom.white")}</span></div>)}</ModuleWrapper>
  )
}
