import React from 'react'
import {WTableWrapper} from "../Widget.style"
import {Text} from "@botndot/ui"
import PropTypes from 'prop-types';
/*  */
export default function WTable({title ,subTitle,  data }) {

    return (
        
            <WTableWrapper >
        {   (Boolean(title||subTitle))&& (        <div className="title">
                    <h1><Text {...title} /></h1>
                    <div>
                        <div className="subTitle" > <Text {...subTitle} /> </div>
                    </div>
                </div>)}
                {data.map((item, i) => (
                        <div key={i} className="infoWrapper">
                            <div className="label"> <span><Text {...item.label}  /></span>  </div>
                            <div className="contentInfo"><Text {...item.value}  /></div></div>))}

                        
             </WTableWrapper>


   
    )
}
         


WTable.defaultProps = {

    
}