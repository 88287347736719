import { Menu , Spin} from '@botndot/ui';
import { useMutation, useQuery } from "@apollo/react-hooks";
import { getIcon } from '@botndot/assets/Icons';
import { gqlService } from "@botndot/core";
import {MenuWrapper} from "./MenuSide.style"
import {getSetting} from "@botndot/lib/helpers/settings"
const { SubMenu } = Menu;
var _ = require("lodash");
export default function MenuSideBar({moduleName , switchView}) {


  const { data, loading } = useQuery(gqlService.GET_SETTINGS, {
    variables:{filters:[{property:"type" , op:{eq:"module" }},{property:"nameSpace" , op:{eq:moduleName }}] }
  });

  if(loading) return <Spin/>
  let modulsSettings=_.head(_.get(data,"settings",[]));
  let menu=getSetting(_.get(modulsSettings,"data",[]), "singleMenu")


const  constructMenu=function(){

return (<Menu 

    defaultSelectedKeys={['0']}
    selectable={true}
    defaultOpenKeys={['0' ]}
    mode="inline" 
    expandIcon={null}>
      

{menu.map( (subMenu , i)=>{

  if(!(subMenu.children||[]).length){
    return <Menu.Item  onClick={()=>{switchView([subMenu.field])}   }  key={i} icon={subMenu.icon && getIcon(subMenu.icon) } >{subMenu.label}</Menu.Item> }
 else {

return ( <SubMenu   key={i} icon={subMenu.icon && getIcon(subMenu.icon) } title={subMenu.label}>

{subMenu.children.map( (menuItem , j)=>(

<Menu.Item onClick={()=>{switchView([subMenu.field, menuItem.field])}   } key={i+"-"+j} icon={menuItem.icon&& getIcon(menuItem.icon) } >{menuItem.label   }</Menu.Item>

)

)}

  </SubMenu>)}


})}

</Menu>)


}

return(
<MenuWrapper>

{ constructMenu(menu)}
</MenuWrapper>
      
)
  
}

