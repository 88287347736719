
import  {moduleName}  from "#config"

export function addHeader(local){

    _.mapKeys(local, function(value, key) {
        return key + moduleName;
      });




}