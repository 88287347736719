import { getName, getUser } from "#helpers/utility";
import gqlService from "#services/gql";
import { useQuery } from "@apollo/react-hooks";
import { Pie } from "@botndot/components";
import { formatDate, sameDay } from "@botndot/lib/helpers/dates";

var _ = require("lodash");

function RepartitionOffre({ type, critere, ...props }) {
  const user = getUser();
console.log("ici")
  const { data, loading } = useQuery(gqlService.GET_LIST_LIGHT, {
    variables: { where: { conseiller_eq: user._id }, sort: "createdAt" },
  });

  const MS = _.get(data, getName("serviceNameP"), []);

  let repartition = [];

  if (MS.length) {
    MS.forEach((offre) => {
      offre.souscriptions.forEach((s) => {
        let rep = repartition.find((r) => r.name == s.typeContrat);

        if (!rep) {
          rep = { name: s.typeContrat, value: 0 };
          repartition.push(rep);
        }

        rep.value =(type=="count" ?  rep.value +1 : rep.value + s[critere]);
      });
    });
  }

  return <Pie data={repartition} />;
}

RepartitionOffre.defaultProps = {
  critere: "montantBrut",
  type: "value",
};

export default RepartitionOffre;
