
import React from 'react';
import { Provider } from 'react-redux';
import AppProvider from './AppProvider';
import Boot from './Boot';
import { store } from './store';
import Routes from './routes';

import   GlobalStyles from "./styles/globalStyle"


const App = () => ( 
  <Provider store={store}>
    <AppProvider>
      <>
        <GlobalStyles />
        
        <Routes />
      </>
    </AppProvider >
  </Provider> 
);
Boot()
  .then(() => App())
  .catch(error => console.error(error));

export default App;  
