import { Tabs } from 'antd';
import AntTab from './Tab.style';
// import WithDirection from '@botndot/lib/helpers/rtl';

 const WDTabs = AntTab(Tabs);
// const TabPane = Tabs.TabPane;
// const isoTabs = WithDirection(WDTabs);

export default WDTabs;

