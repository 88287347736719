import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Topbar from "./Topbar";
import Desktop from "./Desktop";
import { DashboardContainer, DashboardGlobalStyles } from "./Dashboard.styles";
import useWindowSize from "@botndot/lib/hooks/useWindowSize";
import appActions from "../../redux/app/actions";
import { Scrollbar, Layout } from "@botndot/ui";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
let _ = require("lodash");

const { Content } = Layout;
const { toggleAll } = appActions;

export default function Dashboard({ modules }) {
    const dispatch = useDispatch();
    const appHeight = useSelector((state) => state.App.height);
    const isFullScreen = useSelector((state) => state.App.isFullScreen);
    const { width, height } = useWindowSize();

    const maxW = width;
    const maxH = height - 50;
    const styles = {
        layout: { flexDirection: "row", overflowX: "hidden" },
        content: {
            margin: isFullScreen ? 0 : "50px 0 0",
            flexShrink: "0",
            position: "relative",
            overflowX: "hidden",
            overflowY: "auto",
        },
    };
    React.useEffect(() => {
        dispatch(toggleAll(width, height));
    }, [width, height, dispatch]);
    return (
        <DndProvider backend={HTML5Backend}>
            <DashboardContainer>
                <DashboardGlobalStyles />
                <Layout style={{ height: height }}>
               
                    <Topbar modules={modules} />
                    <Layout style={styles.layout}>
                        {/* <Sidebar /> */}
                        <Layout
                            className="isoContentMainLayout"
                            style={{
                                height: appHeight,
                            }}>
                            <Content className="isomorphicContent" style={styles.content}>
                                <Scrollbar style={{ height: maxH }}>
                                    <Desktop autoresize={false} compactType="vertical" width={maxW} height={maxH} />
                                </Scrollbar>
                            </Content>
                        </Layout>
                    </Layout>
                </Layout>
            </DashboardContainer>
        </DndProvider>
    );
}
