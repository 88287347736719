import React from 'react'
import {ColumnItemWrapper} from "./ColumnItem.style"


const renderContent=(type , content , link)=>{
                        
    switch (type) {
case "text"    :  return content 
default : return content
    }

}
export default function ColunmItem( {type ,content ,link , isSelected   , onClick}) {




    return (
        <ColumnItemWrapper onClick={onClick} isSelected ={isSelected}>
            
           {renderContent(type , content , link)}
          
        </ColumnItemWrapper>
    )
}
