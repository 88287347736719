import {Space, Avatar,List} from "@botndot/ui"
import React from 'react'
import {FinancialItemWrapper} from "./FinancialItem.style"
export default function FinancialItem({item, onClick, onDoubleClick ,isSelected}) {

    return (
        <FinancialItemWrapper  isSelected={isSelected} >
<List.Item key={item._id}  onClick={onClick}  onDoubleClick={onDoubleClick}  actions={[(<div>{item.company}</div>)]} >
                <List.Item.Meta
                  
                  description={item.email}
                  title={item.name}
                />
                <div></div>
              </List.Item></FinancialItemWrapper>
    )
}
