
import saMessages from '../locales/es_ES.json';
import {addHeader} from "../helpers"
const saLang = {
  messages: {
    ...addHeader(saMessages),
  },

  locale: 'es',
};
export default saLang;
