import actions from './actions';
import { getToken, clearToken,setToken } from '@botndot/lib/helpers/utility';
const initState = { idToken: null , user:{} , conseiller:{} };

export default function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
setToken(action.token,action.user)

      return {
        idToken: action.token,
        user:action.user,
        conseiller:action.user
      };
      case actions.CHECK_AUTHORIZATION:
        const idToken = getToken().get('idToken');
        if (idToken) {
          const user = getToken().get('user');
          const conseiller=getToken().get('user');
          return {
            idToken,
            user,
            conseiller
          };  

        }

    case actions.SETCONSEILLER     :

      return { ...state,conseiller:action.conseiller}

    case actions.LOGOUT:
     
      clearToken()
      return initState;
    default:
      return state;
  }
}
