import { gql } from 'apollo-boost';

import { name } from '#config';
const singlelName = name;
const Singlename = _.capitalize(name);
const plurialName = name + 's';

const itemDetails = `
id
srri
riskProfile
montantInitial
versementPeriodique
anneeDeblocageF
principalObjectif
report
actions
horizon
allocation
state
code
type
abattement
revenue
fraisReels
enfants
activite
plafondNonUtilise
disponibleFiscale
trancheMarginal

avantageFiscale
disponibleFiscalSuiv
effortEpargne
versementPlafond
valeurPotentielle
plusValue
updatedAt
volatility
return
maxLoss
reaction20
portfolioChoice
createdAt
investRatio
`;

export default {
  GET_ONE: gql` query GET_ONE($id:ID!){ 
    ${singlelName}( id:$id ) {
     ${itemDetails}
    }
}`,

  GET_LIST: gql`
query GET_LIST($where:JSON){ 
  ${plurialName} (where:$where ){
    
     ${itemDetails}
     _id
}} `,
  CREATE_ONE: gql` 

mutation CREATE_ONE($input: create${Singlename}Input) {
  create${Singlename}(input: $input) 
    {
     offre {  ${itemDetails}}
    }
  }


  `,
};
