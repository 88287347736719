import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';
export default function Bar({title , series, sortable=true ,xAxis ,yAxis ,colors , colorsType}) {
const theme=useContext(ThemeContext)
const colorsRange=colors||theme.palette[colorsType||"BarChart"]

const   option = {
    color: colorsRange,
    legend: {
        bottom:20,
        data: series.map(s=>(s.name))
      },

      toolbox: {
        top:20,
        feature: {
          dataView: { show: true, readOnly: false },
          restore: { show: true },
          saveAsImage: { show: true }
        }
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross'
        }
      },
  dataset: [{
      dimensions:   [  'value','name' ],
      source: series,  },
  
 {    transform: sortable?{
          type: 'sort',
          config: { dimension: 'value', order: 'asc' }
      }:{}}
  ],
  grid: {containLabel: true},
  xAxis: {...xAxis}  ,
  yAxis: {
    
    axisLine: {
        // 轴线
        show: true,
        lineStyle: { width: 1,
        },
    },
    
    splitLine: {show: false},
    ...yAxis},

  series: series
};
if(title) option.title=  [ {text:title,x: 'center', y: '0%', textStyle:{color:"#333",fontSize:"14"}},];

    return (
        <ReactEcharts
        option={option}
        notMerge={true}
        style={{height:  "100%", width: "100%"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}