import { isServer } from '@botndot/lib/helpers/isServer';
import actions, { getView } from './actions';

var _ = require("lodash")
export const initState = {
  collapsed: !isServer && window.innerWidth > 1220 ? false : true,
  view: !isServer && getView(window.innerWidth),
  height: !isServer && window.innerHeight,
  isFullScreen:false,
  current: "Home",
layouts:[],
modules:[],
openKeys: ['inbox'],
activeModules:['Home'],



};


export function initApp(newModules){
 console.log("app initialisation")

  initState.modules=newModules
  initState.layouts= initState.modules.map(module => {
  let layout=  module.layout || {
    x:0 , 
    y:0 ,  
  }

    layout.key= module.key ,
    layout.component= module.component,
    layout.state="normal"  ,// fullScreen , minimized
    layout.isNew=true
 return layout;

});


}

export default function appReducer(state = initState, action) {
  let layout={}
  switch (action.type) {
     
    case actions.OPEN_MODULE:
      
      state.activeModules.push(action.key)
      layout= _.find(state.layouts  , {key:action.key})||{}
      layout.state="normal"

      return {
        ...state,
        current:action.key,
        activeModules:_.uniq(  state.activeModules )
      };


      case actions.CLOSE_MODULE:
      
  
         layout= _.find(state.layouts  , {key:action.key})||{}
        layout.width=null
        layout.height=null
        _.remove(state.activeModules, function(key) {   return key == action.key;    })
        return {
          ...state,
  
        };


    case actions.COLLPSE_CHANGE:
      return {
        ...state,
        collapsed: !state.collapsed,
      };
    case actions.TOGGLE_FULLSCREEN:
      return {
        ...state,
        isFullScreen: action.isFullScreen,
      };
    case actions.TOGGLE_ALL:
      if (state.view !== action.view || action.height !== state.height) {
        const height = action.height ? action.height : state.height;
        return {
          ...state,
          collapsed: action.collapsed,
          view: action.view,
          height,
        };
      }
      break;
    case actions.CHANGE_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.openKeys,
      };
    case actions.CHANGE_CURRENT:
      return {
        ...state,
        current: action.current,
      };
    case actions.CLEAR_MENU:
      return {
        ...state,
        openKeys: [],
        current: "Home",
      };
    default:
      return state;
  }
  return state;
}
