

import { Layout, Menu } from "@botndot/ui";
import clientModule from "@perenys/module-client";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import appActions from "../../../redux/app/actions";
import TopbarWrapper from './Topbar.styles';
import TopbarUser from './TopbarUser';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
const {ClientMenu}=clientModule.widgets
const { Header } = Layout;
const { openModule , toggleCollapsed } = appActions;

export default function Topbar({modules}) {

  const [selectedItem, setSelectedItem] = React.useState('');
  const customizedTheme = useSelector(   (state) => state.ThemeSwitcher.topbarTheme );

  const dispatch = useDispatch();
  const client=useSelector((state) => state.Clients.selectedItem )

  const openM=(key)=>{  dispatch(openModule(key)) }


const isDisabled=(module)=>{
let result=false
if(module.dependsOn){

  module.dependsOn.forEach(dep =>{

 if(!client) {result=true}

  })


}
return result

}
//if(ClientModule){ListClients=ClientModule.widgets.ListClients}
  const styling = {
    background: customizedTheme.backgroundColor,
    position: 'fixed',
    width: '100%',
    height: 70, 
  };
  return (
    <TopbarWrapper>
      <Header style={styling} className= 'isomorphicTopbar' >
        <div className="isoLeft">

        {(modules||[]).map((module) => {
          let Component=_.get(module , "menu.left.component")
  return (_.get(module , "menu.left")  &&  !isDisabled(module)  )&&  <Component key={module.key} title={module.key}  onClick={()=>{  openM(module.key)}} />
         } )}


      

        </div>
        <div className="isoCenter">
  <Menu  mode="horizontal" >


        {(modules||[]).map((module) => {
          let Component=_.get(module , "menu.center.component")
  return (_.get(module , "menu.center")  &&  !isDisabled(module)  )&&  <Component key={module.key} title={module.key}  onClick={()=>{  openM(module.key)}} />
         } )}


    </Menu></div>
        <ul className="isoRight">


{ _.map(modules,(module) =>{
          let Component=_.get(module , "menu.right.component")
  return (_.get(module , "menu.right")  &&  !isDisabled(module)  )&&  <li  className="isoMsg"><Component Wrapper={TopbarDropdownWrapper} key={module.key}  /></li> 
         } )}




          <li onClick={() => setSelectedItem('user')} className="isoUser">
            <TopbarUser  />
          </li>
        </ul>
      </Header>
    </TopbarWrapper>
  );
}
