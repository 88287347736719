import { useMutation, useQuery } from "@apollo/react-hooks";
import { IconText } from "@botndot/components";
import { gqlService } from "@botndot/core";
import { Input, Spin } from "@botndot/ui";
import React, { useState } from "react";
import { Droppable } from "../DragnDrop";
import { FileSideBarWrapper } from "./Files.style";
import {ContextMenu , ContextMenuTrigger} from "@botndot/components"

var _ = require("lodash");
export default function FilesSideBar({ moduleName  , setSearch, params}) {
    const [editedItem, setEditedItem] = useState()
    const { data, loading, error } = useQuery(gqlService.GET_FOLDERS, {
        variables: { refresh: params.refresh, filters: [{ property: "module", op: { eq: moduleName } }] },
    });


    const [removeFolder] = useMutation(gqlService.REMOVE_FOLDER, {
        onCompleted: (data) => {
            params.folder= null ;
            setSearch(params )
        },
    });
    const [createFolder] = useMutation(gqlService.CREATE_FOLDER, {
        onCompleted: (data) => {
            params.folder=data.folderCreate._id  ;
            setSearch(params )
        },
    });
    const [updateFolder] = useMutation(gqlService.UPDATE_FOLDER, {
        onCompleted: (data) => {
            
        //    params.folder= data.folderUpdate._id ;
            setSearch(params )
        },
    });

    if (loading) return <FileSideBarWrapper><Spin /></FileSideBarWrapper>  ;
    const Folders = data.folders;

    const handleAddItems = (item , target) => {
        updateFolder({ variables: { id: target , items:[item._id], op:"addItems" } });
    };

    

    const renamefolder=(e)=>{

        updateFolder({ variables: { id: editedItem , name:e.target.value , op:"update" } });
        setEditedItem(null)
    
       }



     const handleClickFolder=(folder)=>{  
        params.folder= folder ;
         setSearch(params ) }

    const subMenus = [
        { title: "Main", priority: 1 },
        { title: "Buckets", priority: 2 },
        { title: "Tags", priority: 3 },
    ];
    subMenus.forEach((group) => {
        let menuGroup = _.filter(Folders, { group: group.title });

        group.menuItems = menuGroup.map((f) => {
            let newF = {};
            newF._id = f._id;
            newF.content = f.name;
            
            newF.count = f.items.length;
            newF.icon = "navigation." + f.icon + "." + f.color;
            if (f.isDroppable) {
                newF.dropProps = { dropAction: handleAddItems, target:newF._id,  type: moduleName, key: f.name };
            }

            return newF;
        });
    });

    let menu = {
        props: {},

        subMenus,
    };



    const constructFileBrowser = function (menu) {
        return (
            <FileSideBarWrapper className="sidebar" {...menu.props}>
                {menu.subMenus.map((subMenu, i) => (
                    <li key={i}>
                        <ContextMenuTrigger id={"Group"} holdToDisplay={1000} attributes={{ "data-group": subMenu.title }}>
                            {" "}
                            <p className="titleGroup">{subMenu.title}</p>
                        </ContextMenuTrigger>
                        <ul>
                            {subMenu.menuItems.map((menuItem, j) => {
                                let Item = (
                                    <ContextMenuTrigger id={"Folder"} holdToDisplay={1000} attributes={{ "data-id": menuItem._id }}>
                              <IconText onClick={()=>{handleClickFolder(menuItem._id )}} icon={menuItem.icon} count={menuItem.count}>
                                        {   ( menuItem._id!=editedItem )    ?     menuItem.content    :  
                                        <Input   onPressEnter={renamefolder}   defaultValue={menuItem.content} />  }
                                        </IconText>
                                  
                                    </ContextMenuTrigger>
                                );

                                return <div key={i + "-" + j}>{menuItem.dropProps ? <Droppable    {...menuItem.dropProps}>{Item}</Droppable> : Item}</div>;
                            })}
                        </ul>
                    </li>
                ))}
            </FileSideBarWrapper>
        );
    };



    const actionsGroup=[
{label: "New Folder" , 
  action:(e, data , target)=> {
                                let group = target.getAttribute("data-group");
                                createFolder({ variables: { name: "New File", group: group, module: moduleName } });
                                                                }   
}
    ]


    const actionsFolder=[
        {label: "Rename Folder" , 
          action:(e, data , target)=> {    setEditedItem(target.getAttribute("data-id") ); }   
        },
        {label: "Empty Folder" , 
          action:(e, data , target)=> {    updateFolder({ variables: { id: target.getAttribute("data-id") , op:"deleteAllItems" } });}   
        }
        ,
       {label:"Remove Folder",
         action:()=>{  removeFolder({ variables: { id: target.getAttribute("data-id") } });}
        }
            ]

    return (
        <>
            {constructFileBrowser(menu)}
            <ContextMenu actions={actionsFolder}  id={"Folder"}/>
            <ContextMenu actions={actionsGroup} id={"Group"} />
        </>
    );
}

