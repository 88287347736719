
import { modules } from '@config';
import { combineReducers } from 'redux';
import   {  Auth_R  as  Auth,
                App_R  as App ,
  ThemeSwitcher_R as ThemeSwitcher,
  LanguageSwitcher_R as LanguageSwitcher,initApp }  from "@botndot/core"
initApp(modules)

let reducers={
  Auth,
  App,
  ThemeSwitcher,
  LanguageSwitcher
}

modules.forEach(module=>{

  if(module.redux )  reducers[module.redux.name]= module.redux.reducer ;


})



export default combineReducers(reducers);
