import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const TableWrapper  = styled.div`


 .ant-table-wrapper {
    height: 100%;
    background: white;
    .ant-spin-nested-loading {
      height: 100%;
  
      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
  
        .ant-table {
          flex: auto;
          overflow: hidden;
          table{ text-align:center;}
          .ant-table-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
  
            .ant-table-header {
              flex: none;
            }
  
            .ant-table-body {
              flex: auto;
              overflow: scroll;
            }
          }
        }
  
        .ant-table-pagination {
          flex: none;
        }
      }
      td{padding:3px;}

.ant-table-thead > tr > th {
        color: rgb(255 255 255);
        font-weight: 600;
        text-align: center;
        background: #1cbbfb;
        border-bottom: 1px solid #f0f0f0;
        -webkit-transition: background 0.3s ease;
        transition: background 0.3s ease;
        padding: 10px 6px;

        &:first-child{text-align:right;}
    }}
 ` 

 export const   TableBrowserWrapper  = styled.div`
 height:100%;
 .ant-table-wrapper {
    height: 100%;
    background: white;
    .ant-spin-nested-loading {
      height: 100%;
  
      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;
  
        .ant-table {
          flex: auto;
          overflow: hidden;
  
          .ant-table-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;
  
            .ant-table-header {
              flex: none;
            }
  
            .ant-table-body {
              flex: auto;
              overflow: scroll;
            }
          }
        }
  
        .ant-table-pagination {
          flex: none;
        }
      }
      td{padding:0px;}
 ` 