



import clientModule from "@perenys/module-client";
import offreModule from "@perenys/module-offre";
import simulationModule from "@perenys/module-simulation";
import homeModule from "@perenys/module-home";
import contactModule from "@perenys/module-mails";
export const url=process.env.REACT_APP_SERVERURL
export const loginUrl=url+"/auth/local"
export const apiUrl=url+"/graphql"
export const modules= [homeModule, clientModule,offreModule,simulationModule,contactModule ]


     