import Image from "@botndot/assets/images/envelope.png";
import { Card, Avatar } from '@botndot/ui';
import { getIcon } from "@botndot/assets/Icons";
import React ,{useState} from "react";
import { useSelector } from "react-redux";
import { MenuWrapper } from "./MenuStyle";
import undifined from "@botndot/assets/images/users/undifined.png"
import { TopbarMessageIcon } from "@botndot/assets/Icons/navigation";
import { Scrollbar, Popover, Text } from "@botndot/ui";
import { IntlMessages } from "@botndot/components";
import { useQuery } from "@apollo/react-hooks";
import { getDispatch, getName, getState, getUser } from "#helpers/utility";
import gqlService from "#services/gql";
const { Meta } = Card;
export default function TopbarMessage({ Wrapper }) {
  const { selectedItem } = getState();
  const [single, setsingle] = useState(false);
  const dispatch = getDispatch();
  const user = getUser();
  if (user.id !="628ea7e2e95a861ac58bbf9c") return (<div></div>)
  const { data, loading } = useQuery(gqlService.GET_LIST, {
    variables: { sort:"createdAt:desc"  },
    fetchPolicy: "network-only",
  });

  const MS = _.get(data, getName("serviceNameP"), []);

  const renderItem = (M) => {
    return (
      <a
        onClick={() => {
          dispatch("setSelectedItem", M);
          setsingle(true);
        }}
        className='isoDropdownListItem'
        key={M.id}
        href='# '
      >
        <div className='isoImgWrapper'>
          <img alt='#' src={Image} />
        </div>

        <div className='isoListContent'>
          <div className='isoListHead'>
            <h5>
              <Text content={""} options={{ type: "name", prenom: M.prenom, nom: M.nom }} />
            </h5>
            <span className='isoDate'>
              <Text content={M.createdAt} options={{ type: "date", fromNow: true }} />
            </span>
          </div>
          <p>
            <Text content={M.objet || M.message} options={{ type: "text", length: 80 }} />
          </p>
        </div>
      </a>
    );
  };


const   renderCard= (M)=>{


return (      <Card 
   

  actions={[
    <a  onClick={()=>{setsingle(false)}}>{getIcon("navigation.left")}</a>,
<a href={"mailto:"+M.email}>{getIcon("actions.email")}</a>,
<a href={"tel:"+M.phone} >{getIcon("actions.call")}</a>,
  ]}
>
  <Meta
    avatar={<Avatar src={undifined} />}
    title={ <Text content={""} options={{ type: "name", prenom: M.prenom, nom: M.nom }} />}
    description={<Text content={M.phone}  />}
  />
  <div className="body">
 {M.solution && <h3 className="subtitle">{M.solution}</h3>}
 {M.objet && <h3 className="subtitle">{M.objet}</h3>}
<p > {M.message}</p></div>
 
</Card>)


  }
  

  const renderList=()=>{

    return (  <> <div className='isoDropdownHeader'>
    <h3>
      <Text content={"Messages"} />
    </h3>
    </div>
    <div className='isoDropdownBody'>
    <Scrollbar style={{ height: 300 }}>{MS.map((M) => renderItem(M))}</Scrollbar>
    </div>
    <a className='isoViewAllBtn' href='# '>
    <IntlMessages id='topbar.viewAll' />
    </a></>)
    
    
    }
    

  const [visible, setVisiblity] = React.useState(false);
  const customizedTheme = useSelector((state) => state.ThemeSwitcher.topbarTheme);

  function handleVisibleChange() {
    setVisiblity((visible) => !visible);
  }
  const content = (
    <Wrapper className='topbarMessage withImg'>
      <MenuWrapper>
   {single ?

renderCard(selectedItem)
   
   
   
   : renderList() }
      </MenuWrapper>
    </Wrapper>
  );
  return (
    <Popover content={content} trigger='click' visible={visible} onVisibleChange={handleVisibleChange} placement='bottomLeft'>
      <div className='isoIconWrapper'>
        <TopbarMessageIcon size={24} color={customizedTheme.textColor} />
        <span>{MS.length}</span>
      </div>
    </Popover>
  );
}
