import userpic from '@botndot/assets/images/users/undifined.png';
import React from 'react';
import {getMedia} from "#helpers/utility"
import { Link } from 'react-router-dom';
import TopbarDropdownWrapper from './TopbarDropdown.styles';
import   authActions  from "../../../redux/auth/actions"
import   {Popover}  from  "@botndot/ui"
import { useDispatch, useSelector } from 'react-redux';
import {IntlMessages} from "@botndot/components"
const { logout } = authActions;

export default function TopbarUser() {
  const [visible, setVisibility] = React.useState(false);
  const dispatch = useDispatch();
  function handleVisibleChange() {
    setVisibility(visible => !visible);
  }
  const user=useSelector((state) => state.Auth.user )
  const content = (
    <TopbarDropdownWrapper className="isoUserDropdown">
      <Link className="isoDropdownLink" to={'/dashboard/my-profile'}>
        <IntlMessages id="topbar.myprofile" />
      </Link>

      <a className="isoDropdownLink" target="_blank" href="https://api.whatsapp.com/message/RPXKNZ5AK6BHA1?autoload=1&app_absent=0 ">
        <IntlMessages id="topbar.help" />
      </a>
      <a
        className="isoDropdownLink"
        onClick={() => dispatch(logout())}
        href="# "
      >
        <IntlMessages id="topbar.logout" />
      </a>
    </TopbarDropdownWrapper>
  );

  return (
    <Popover
      content={content}
      trigger="click"
      visible={visible}
      onVisibleChange={handleVisibleChange}
      arrowPointAtCenter={true}
      placement="bottomLeft"
    >
      <div className="isoImgWrapper">
        <img style={{borderRadius:"50%"}} alt="user"      src={(user.avatar) ? getMedia(user.avatar.url) :userpic} />
        <span className="userActivity online" />
      </div>
    </Popover>
  );
}
