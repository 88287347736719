
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import request from 'superagent';
import SignInStyleWrapper from './SignIn.styles';
import   {App_A as appActions,Auth_A as authActions} from "../../../redux"
import   {Button, Form, Input }  from "@botndot/ui"
import   {IntlMessages}  from "@botndot/components"

const { loginSuccess } = authActions;
const { clearMenu } = appActions;



export default function SignIn({url}) {
  let history = useHistory();
  let location = useLocation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.Auth.idToken);

  const [redirectToReferrer, setRedirectToReferrer] = React.useState(false);
  React.useEffect(() => {
    if (isLoggedIn) {
      setRedirectToReferrer(true);
    }
  }, [isLoggedIn]);


  let { from } = location.state || { from: { pathname: '/dashboard' } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
   
    request
    .post(url)
    .set('Content-Type', 'application/json')
 
 
    .send(values)
    .end((error, res) => {
      
      if (error) {
        console.log('Failed:', error);
 
      } else {
      
        dispatch(loginSuccess(res.body.jwt , res.body.user));
        dispatch(clearMenu());
        history.push('/dashboard');
 
      }
    }); 
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <SignInStyleWrapper className="isoSignInPage">
      <div className="isoLoginContentWrapper">
        <div className="isoLoginContent">
          <div className="isoLogoWrapper">
            <Link to="/dashboard">
              <IntlMessages id="page.signInTitle" />
            </Link>
          </div>
          <div className="isoSignInForm">
    


          <Form
      {...layout}
   
      initialValues={{ remember: true }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Form.Item
        label="Login"
        name="identifier"
        rules={[{ required: true, message: 'Please input your username!' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Mot de passe"
        name="password"
        rules={[{ required: true, message: 'Please input your password!' }]}
      >
        <Input.Password />
      </Form.Item>

      <Form.Item {...tailLayout} name="remember" valuePropName="checked">

      </Form.Item>

      <Form.Item {...tailLayout}>
        <Button style={{width:200}} type="primary" htmlType="submit">
          Ouvrir une session
        </Button>
      </Form.Item>
    </Form>

            <div className="isoInputWrapper isoOtherLogin">

           
            </div>
            <div className="isoCenterComponent isoHelperWrapper">
              <Link to="/forgotpassword" className="isoForgotPass">
                <IntlMessages id="page.signInForgotPass" />
              </Link>
              <Link to="/signup">
                <IntlMessages id="page.signInCreateAccount" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </SignInStyleWrapper>
  );
}
