import zhMessages from '../locales/zh-Hans.json';
import {addHeader} from "../helpers"
const ZhLan = {
  messages: {
    ...addHeader(zhMessages),
  },

  locale: 'zh-Hans-CN',
};
export default ZhLan;
