
import React, { lazy } from "react";
import reducer from "./redux/reducer";
import locale from "./translation";
import {getIcon} from "@botndot/assets/Icons";
import widgets from "./wigets"
import {moduleName , reduxName} from "#config"

export default  {
      key: moduleName,
      label: "sidebar."+moduleName,
      icon:<span>{getIcon("navigation.folder.black")} Offres</span>,
      menu:{ center :{component:widgets.Menu} },
      path: moduleName,
      component:  lazy(() => import("./screens")),
      redux : {name:reduxName, reducer},
      dependsOn:["Clients"],
      widgets:widgets,
      locale 
      
    }