
import {
MdEdit,MdSave,MdRemoveCircle
} from 'react-icons/md';
import {FiFilter} from "react-icons/fi"
import {RiMailSendFill} from "react-icons/ri"
import {IoMdCall} from "react-icons/io"
//Navigation 
export const EditIcon =MdEdit
  export const SaveIcon=MdSave
export const RemoveIcon =MdRemoveCircle
export const  FilterIcon=FiFilter
export default function getIcon(icon){ 

switch(icon){

  case "edit": return EditIcon;
case "save" :return SaveIcon;
case "delete" : return RemoveIcon;
case 'filter': return FilterIcon;
case "email" : return RiMailSendFill
case "call" :  return IoMdCall
default : return ""
}


}