
var moment = require('moment');




  export const yesterday=function(){
    var d = new Date();
    d.setDate(d.getDate() - 1);

return d
}

export const sameDay = function(date1, date2) {
  let d1=new Date(date1)
let d2=new Date(date2)
  return d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

export const sameMonth = function(date1, date2) {
let d1=new Date(date1)
let d2=new Date(date2)

    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() 

  }

  export const getYesterday=function(){
    var d = new Date();
    d.setDate(d.getDate() - 1);

return d
}

export const getNextDay=function(today){

    var tomorrow=new Date(today) ;
    if (today.getDay() == 5)   tomorrow.setDate(tomorrow.getDate() + 3);
    else {   tomorrow.setDate(today.getDate() + 1);} 

return tomorrow
}

export function getPrevDay(today){

  var yesterday=new Date(today) ;
  if (today.getDay() == 1) { 
     yesterday.setDate(today.getDate() - 3);}
  else if (today.getDay() == 0) {
      yesterday.setDate(today.getDate() - 2);}
  else {  
     yesterday.setDate(today.getDate() - 1);} 

return yesterday
}

export const todayDate=function(){

let today= new Date(new Date().getFullYear(),new Date().getMonth() , new Date().getDate())
if(isBusinessDay(today)) return today
else return getPrevDay(today)
}

export const businessDate=function(date){

  let today= new Date(new Date(date).getFullYear(),new Date(date).getMonth() , new Date(date).getDate())
  if(isBusinessDay(today)) return today
  else return getPrevDay(today)
  }

export function randomDate(start, end) {
  let d = new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );

  if (d.getDay() == 0) d.setDate(d.getDate() + 1);
  if (d.getDay() == 6) d.setDate(d.getDate() - 1);

  return d;
}




export function workingDays(startDate, endDate) {
  
if(!(startDate instanceof Date )) startDate=new Date(startDate)
if(!(endDate instanceof Date )) endDate=new Date(endDate)
let direct=(startDate<=endDate)? 1 : -1
let difference= moment(endDate).diff(moment(startDate), 'days')
let nbweeks=(difference- ((5-startDate.getDay() )  +(2+endDate.getDay()) ))/7
let res2=nbweeks*5 + (5-startDate.getDay() ) + endDate.getDay()
//let res =direct*moment(startDate).businessDiff(moment(endDate))
//if(res!=res2) debugger
return res2

}

export function diffDays(debut, fin) {

let   startDate=moment(new Date(debut))
let   endDate=moment(new Date(fin))

return endDate.diff(startDate, 'days')


}

export function isMonthStart(today){

let yesterday=getPrevDay(today)


return isBusinessDay(today) && !sameMonth(today, yesterday) && isBusinessDay(yesterday)



}

export function isBusinessDay(d){

return (d.getDay() != 0) && (d.getDay() != 6)

}


export function getYearRange(date ,int){

  let result = moment(date).subtract(int, 'year').toDate() 

  if (result.getDay() == 6)   result.setDate(result.getDate() - 1);
  if (result.getDay() == 0)   result.setDate(result.getDate() - 2);
  return workingDays(result,date)+1
}


export function formatDate(date , format= "DD/MM/YYYY", fromNow=false){

  if(!(date instanceof Date )) date=new Date(date)

  return fromNow ?moment(date,format).fromNow() : moment(date).format(format); 

}









export function getPrevWorkingDay(today){

  var yesterday=new Date(today) ;
  if (today.getDay() == 1) { 
     yesterday.setDate(today.getDate() - 3);}
  else if (today.getDay() == 0) {
      yesterday.setDate(today.getDate() - 2);}
  else {  
     yesterday.setDate(today.getDate() - 1);} 

return yesterday
}



