import * as Window   from "react-spaces";
import Layout from "./Layout"
import {LayoutWrapper} from "./Window.style"
import React from 'react'

 function FillContent({children}) {
  return (
    <LayoutWrapper>          
    <div className="LayoutContent">
        {children}</div></LayoutWrapper> )
}


export default {...Window, Layout  ,FillContent}