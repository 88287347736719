import styled from "styled-components" 
  import {palette} from "styled-theme"  
  const borderRadius=10
 export const SearchWrapper  = styled.div`
 
 
 padding: 10px;
 text-align:center;
background: ${palette('menuColor', 0)};

 height: 100%;
 
 .ant-input, .ant-select-selector{border-radius:${borderRadius}px !important;

 background: white !important;
 margin-top: 10px;
}

.ant-btn{margin-top:5px}

` 