import { List } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {InfinitListWrapper} from "./InfinitList.style"


export default function  InfinitList({dataSource, onClick ,footer, renderItem, loadMore, loading, hasMore }){



    return (
     <InfinitListWrapper>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={loadMore}
                hasMore={!loading && hasMore}
                useWindow={false}
            >
                <List
                onClick={onClick}
                    itemLayout="horizontal"
                    size="large"
                    dataSource={dataSource}
                    footer={footer}
                    renderItem={renderItem}
                    loading={loading}>

                    {loading && hasMore && (
                        <div className="demo-loading-container">

                        </div>
                    )}
                </List>				

            </InfiniteScroll>
</InfinitListWrapper>
    )
}




