import styled from "styled-components"
import {palette} from "styled-theme"


export const  FileSideBarWrapper= styled.ul`
height:100%;
padding: 10px;
background: ${palette('menuColor', 0)};
>.ant-menu{
   

     background: ${palette('menuColor', 1)};

    

}

button{position:absolute ; right:10px;}


input{

     width: 80%;
     background:${palette('menuColor', 1)};
     padding: revert;
     border: none;
     color:${palette('primary', 0)};

}


`


