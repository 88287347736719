
import enMessages from '../locales/en_US.json';
import {addHeader} from "../helpers"
const EnLang = {
  messages: {
    ...addHeader(enMessages),
  },

  locale: 'en-US',
};
export default EnLang;
