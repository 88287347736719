import React, { Fragment } from "react";
import { Badge } from "@botndot/ui";
import { getIcon } from "@botndot/assets/Icons";
import { IconTextWrapper } from "./IcontText.style";
const IconText = ({ icon, count, children ,  onClick}) => (
    <IconTextWrapper  onClick={onClick}>
        <div className="iconLeft">
            {icon &&getIcon(icon)}
            <span className="text"> {children}</span>
        </div>
        {count !=0&&<div className="iconRight"><Badge className="badge"    count={count} /></div> }
    </IconTextWrapper>
);

export default IconText;
