
import  {moduleName}  from "#config"

export function addHeader(local){

 return    _.mapKeys(local, function(value, key) {
        return moduleName+"."+key ;
      });




}