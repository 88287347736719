
import _ from "lodash";
import Salang from './entries/ar_SA';
import Enlang from './entries/en-US';
import Eslang from './entries/es_ES';
import Frlang from './entries/fr_FR';
import Itlang from './entries/it_IT';
import Zhlang from './entries/zh-Hans-CN';

const AppLocale = {
  en: Enlang,
  zh: Zhlang,
  sa: Salang,
  it: Itlang,
  es: Eslang,
  fr: Frlang,
};


AppLocale.merge = (locale={})=>{    
                                                                          AppLocale.en.message=_.assign(AppLocale.en.messages,_.get(locale,'en.messages',{}))
                                                                          AppLocale.zh.message=_.assign(AppLocale.zh.messages,_.get(locale,'zh.messages',{}))
                                                                          AppLocale.sa.message=_.assign(AppLocale.sa.messages,_.get(locale,'sa.messages',{}))
                                                                          AppLocale.it.message=_.assign(AppLocale.it.messages,_.get(locale,'it.messages',{}))
                                                                          AppLocale.es.message=_.assign(AppLocale.es.messages,_.get(locale,'es.messages',{}))
                                                                          AppLocale.fr.message=_.assign(AppLocale.fr.messages,_.get(locale,'fr.messages',{}))
  
  
}


export default AppLocale;



