import styled from "styled-components";
import { palette } from "styled-theme";
export const MenuWrapper = styled.div`
   {
    .body {
      padding: 20px 10px;
      .subtitle {
     
      font-size:13px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: ${palette("primary", 0)};

      }
      p {
        padding-top: 10px;
        text-align: justify;
        color: ${palette("text", 0)};
      }
    }
  }
`;
