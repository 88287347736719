import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const ContextMenuWrapper  = styled.div`

 .react-contextmenu.react-contextmenu--visible {
      background: white;
      padding: 10px;
      border-radius: 7px;
      border-radius: 4px;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
      border: 2px solid transparent;
      font-size: 13px;
      line-height: 27px;
 .react-contextmenu-item{
 
 &:hover{color:red; cursor:pointer;}
 
 }
 
 
 }
 
 
 `