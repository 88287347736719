import React, { lazy } from 'react';
import reducer from './redux/reducer';
import locale from './translation';
import { getIcon } from '@botndot/assets/Icons';
import widgets from './wigets';
import { moduleName, reduxName } from '#config';

export default {
  key: moduleName,
  label: 'sidebar.' + moduleName,
  icon: <span>{getIcon('navigation.folder.black')} Clients</span>,
  menu:{ left :{component:widgets.Menu} },
  path: moduleName,
  component: lazy(() => import('./screens')),
  widgets: widgets,
  redux: { name: reduxName, reducer },
  locale,
};
