export function text_truncate(content, length, ending) {
    if(!length )return content
    let newContent=content
    if (ending == null) {
      ending = '...';
    }
    if (newContent.length > length) { 
        newContent= newContent.substring(0, length - ending.length) + ending;
    } 
    
      
        return newContent;
    }

    export function displayName(prenom="" , nom=""){


return prenom ?  _.capitalize((prenom||" ")[0])+"."+_.capitalize(nom): _.capitalize(nom)

    }