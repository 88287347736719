const theme = {};

theme.palette = {
  gauge: ['#3b5497', '#3b5497', '#1cbbfb'],
};

theme.fonts = {
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
};

export default theme;
