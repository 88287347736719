import { name,moduleName } from "#config";
import actions from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export function getState() {
  return useSelector((state) => state[getName("reduxName")]);
}

export function getUser() {
  return useSelector((state) => state.Auth.user||{});
}

export function getConseiller() {
  console.log(useSelector((state) => state.Auth.conseiller||{}))
  return useSelector((state) => state.Auth.conseiller||{});
}
export function getClient() {
  return useSelector((state) => state.Clients.selectedItem||{});
}

export function getAction(actionName) {
  return actions[actionName]||actionName;
}

export function getName(type) {
  switch (type) {
    case "moduleName":
      return _.capitalize(name) + "s";
    case "reduxName":
      return _.capitalize(name) + "s";
    case "serviceName":
      return _.lowerCase(name);
    case "serviceNameP":
      return _.lowerCase(name) + "s";
    default:
      return name;
  }
}



export function getDispatch(){

   const dispatch=useDispatch()
   return (action,params)=>{
    dispatch(
      getAction(action)(params)
    );

   }

}

export function translate(text){

  return  moduleName+"."+text
 
 
 }

 export const rules ={

  required : {required : true , message : "ce champ est obligatoire"} ,
  string : {  pattern : "^([^0-9])+$" , message: "La valeure saisie n'est pas valide!" },

  phone:{pattern :"^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$" , message: "Le numero saisie  n'est pas valide!"},
email :{ type: 'email', message: "La valeure saisie n'est pas un E-mail valide!" },

date :  (date , message ,  )=>  ( {
  validator: (_, value) =>
    value  > date ? Promise.resolve() : Promise.reject(new Error( message)),
}),
dateBefore :  (date , message ,  )=>  ( {
  validator: (_, value) =>
    value  < date ? Promise.resolve() : Promise.reject(new Error( message)),
})
} 

export function getMedia(url){
  if (_.includes(url , "perenysuploads")  ) return url
  return process.env.REACT_APP_MEDIAURL+url.replace("/uploads/","") }