
import { gql } from 'apollo-boost';

import {name} from "#config"


const itemDetails= ` CSP
nationalite
adresseCP
adressePays
adresseVille
adresseVoie
capaciteJuridique
civilite
cpVilleNaissance
dateNaissance
docType
docValidite
email
enfantCharge
fonctionPpe
fonctionPpeEntourage
id
produitsEthiques
justifDoc
justifFiscal
lienPpeEntourage
nom
numeroFiscal
patrimoine
patrimoineBrute
pays
paysNaissance
paysPpe
paysPpeEntourage
phone
ppe
ppeEntourage
prenom
regimeMatrimonial
revenue
revenueBrute
riskProfile
srri
situationFamiliale
situationPro
state
travailleurNonSalarie

villeNaissance

revenusProfessionnels
revenusLocatifs
revenusAutres

patrimoineEpargne
patrimoineImmo
patrimoineTitre
patrimoineAV
patrimoineAutres

chargesImmobiliers
chargesConsommation
chargesAutres
charges
tauxEndettementEstime
connaissancesNiveau
connaissancesTempsInvest
connaissancesMontantActions
connaissancesFreqActions
connaissancesMontantPERin
connaissancesFreqPERin
connaissancesMontantAV
connaissancesFreqAV
connaissancesMontantOPCI
connaissancesFreqOPCI
connaissancesMontantOPCVM
connaissancesFreqOPCVM
connaissancesMontantSCPI  
connaissancesFreqSCPI
connaissancesMontantFCPI
connaissancesFreqFCPI
connaissancesInvestissement
connaissancesCapacitePerte
connaissancesRisk
updatedAt
createdAt
conseiller{id username role {name}  avatar{url}}
principalObjectif
volatility
return
horizon
maxLoss
reaction20
portfolioChoice
investRatio
`

const souscriptionDetails= ` 
IBAN
dateComptable
dateComptableDay
dateComptableMonth
dateEffet
dateLiquidation
deductibiliteFiscale
dureeDeblocage
id
modeDeGestion
montantBrut
objectifPrincipal
periodeInformation
periodiciteVersement
provenanceFonds
reglement
signature
state
typeContrat
versementProgramme`

const offreDetails=`
code
id
state
souscriptions {code FC  objectifPrincipal typeContrat }
RBE
LM
`

export   default {


 GET_LIST :gql`
 query GET_LIST($where:JSON , $sort:String ){ 
  ${name}s (where:$where , sort:$sort ){
     
      ${itemDetails}
      _id
}} `
,

GET_ONE: gql` query GET_CONTACT($id:ID!){ 
    client( id:$id ) {
     ${itemDetails}
    }
}`,
CREATE_ONE: gql` 

mutation CREATE_CLIENT($input: createClientInput) {
  createClient(input: $input) 
    {
     client {  ${itemDetails}}
    }
  }

`,
UPDATE_ONE: gql` 

mutation UPDATE_ONE($input: updateClientInput) {
 updateClient(input: $input) 
    {
     client {  ${itemDetails}}
    }
  }


  `,

  GET_CONSEILLERS :gql`
  query GET_CONSEILLERS($where:JSON , $sort:String ){ 
   users (where:$where , sort:$sort ){
      
    id username role {name}  avatar{url} group{id} email
  
 }} `
 ,

}






 
