import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';



export default function Bar({title , data, indicator  ,  colorsType}) {
const theme=useContext(ThemeContext)
const colors=theme.palette[colorsType||"BarChart"]
 /*
 Indicators
 [
     { name: 'Sales', max: 6500 },
     { name: 'Administration', max: 16000 },
     { name: 'Information Technology', max: 30000 },
     { name: 'Customer Support', max: 38000 },
     { name: 'Development', max: 52000 },
     { name: 'Marketing', max: 25000 }
   ]

   data
   [{values:[1,2,3,4] , name:"time"}...]
   */

const  option = {

    legend: {
      data: data.map(elem=>elem.name)
    },
    radar: {
      // shape: 'circle',
      indicator
    },
    series: [
      {
        name: 'Budget vs spending',
        type: 'radar',
        data: data
      }
    ]
  };
if(title) option.title=  [ {text:title,x: 'center', y: '0%', x: '70%',textStyle:{color:"#333",fontSize:"14"}},];

    return (
        <ReactEcharts
        option={option}
        notMerge={true}
        style={{height:  "100%", width: "100%"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}