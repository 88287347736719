
import { gql } from 'apollo-boost';

import {name} from "#config"
const singlelName=name
const Singlename=_.capitalize(name)
const plurialName=name+"s"

const itemDetails=`
id
createdAt
company
nom
prenom
message
objet
solution
email
phone
`

export   default {



GET_ONE: gql` query GET_ONE($id:ID!){ 
    ${singlelName}( id:$id ) {
     ${itemDetails}
    }
}`,

GET_LIST :gql`
query GET_LIST($where:JSON , $sort:String){ 
  ${plurialName} (where:$where , sort:$sort ){
    
     ${itemDetails}
     _id
}} `
,


CREATE_ONE: gql` 

mutation CREATE_ONE($input: create${Singlename}Input) {
  create${Singlename}(input: $input) 
    {
     offre {  ${itemDetails}}
    }
  }


  `,







}
 
