import { store } from "./store";
import { Auth_A as authActions } from "@botndot/core";
import axios from "axios";
import {url} from "@config"
export default () => new Promise(() => {
        store.dispatch(authActions.checkAuthorization());
        // axios.post(url+"/auth/local", {
        //         identifier: "hassan.naciri@gmail.com",
        //         password: "Oy7$fAxZA#kj",
        //     })
        //     .then(function (response) {
        //       console.log(response.data.jwt)
        //         store.dispatch(authActions.loginSuccess(response.data.jwt));
        //     });
    });
