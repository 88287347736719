import actions from "./actions";
var _ = require("lodash");

const initState = {
  selectedModule: -1 ,
  filterAttr: { bucket: "Inbox" },
  selectedScreen: { screen: "Single",selectedView:{view: "Main" , subView:null}, props: {}},
  selectedItem: null,
  selectedItems: [],

  SingleState: {
    selectedView:{view: "Main" , subView:null},
  },


};

export default function ModuleReducer(state = initState, action) {
  switch (action.type) {
    case actions.MODULE_INIT:
      return {
        ...initState,
      };

    case actions.SCREEN_SET_SELECTED:
      state.selectedScreen = action.screen;
      return {
        ...state,
      };

    case actions.LIST_SWITCH_VIEW:
      state.ListState.selectedView = action.view;

      return {
        ...state,
      };

    case actions.LIST_TOGGLE_SEARCH:
      state.ListState.isSearch = !state.ListState.isSearch;

      return {
        ...state,
      };
    case actions.SET_SEARCH_PARAMS:
      state.ListState.searchParams = action.searchParams;

      return {
        ...state,
      };

    case actions.SET_SELECTED_ITEM:
      state.selectedItem = action.selectedItem;
      state.selectedItems = [action.selectedItem];
      return {
        ...state,
      };
    case actions.SET_SELECTED_ITEMS:
      if (_.find(state.selectedItems, { _id: action.M._id })) {
        state.selectedItems = _.filter(
          state.selectedItems,
          (item) => item._id != action.M._id
        );
      } else {
        state.selectedItems = _.concat(state.selectedItems, action.M);
      }
      return {
        ...state,
      };
    case actions.RESET_SELECTED_ITEMS:
      state.selectedItems = [];
      return {
        ...state,
      };
    case actions.SINGLE_SWITCH_VIEW:
      state.SingleState.selectedView = action.view;

      return {
        ...state,
      };
    case actions.SINGLE_SWITCH_SUBVIEW:
      state.SingleState.selectedSubView = action.subView;

      return {
        ...state,
      };
    default:
      return state;
  }
}
