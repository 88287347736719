import React, { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./constants";



const Dashboard = lazy(() => import("./Dashboard"));

const publicRoutes = [
    {
        path: PUBLIC_ROUTE.LANDING,
        exact: true,
        component: lazy(() => import("./Pages/SignIn")),
    },
    {
        path: PUBLIC_ROUTE.SIGN_IN,
        component: lazy(() => import("./Pages/SignIn")),
    },
    {
        path: PUBLIC_ROUTE.SIGN_UP,
        component: lazy(() => import("./Pages/SignUp/SignUp")),
    },
];

function PrivateRoute({ children, ...rest }) {
    const isLoggedIn = useSelector((state) => state.Auth.idToken);

    return (
        <Route
            {...rest}
            render={({ location }) =>
                isLoggedIn ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: "/signin",
                            state: { from: location },
                        }}
                    />
                )
            }
        />
    );
}
export default function Routes() {
    return (
        <ErrorBoundary>
            <Suspense fallback={<div>loading</div>}>
                <Router>
                    <Switch>
                        {publicRoutes.map((route, index) => (
                            <Route key={index} path={route.path} exact={route.exact}>
                                <route.component />
                            </Route>
                        ))}
                        <PrivateRoute path="/dashboard">
                            <Dashboard />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </Suspense>
        </ErrorBoundary>
    );
}
