
import React, { lazy } from "react";
import reducer from "./redux/reducer";
import locale from "./translation";
import {getIcon} from "@botndot/assets/Icons";
import widgets from './widgets';
import {moduleName , reduxName} from "#config"

export default  {
      key: moduleName,
      label: "sidebar."+moduleName,
      icon:<span>{getIcon("navigation.folder.black")} Simulations</span>,
      menu:{ left :{component:widgets.Menu} },
      path: moduleName,
      component:  lazy(() => import("./screens")),
      redux : {name:reduxName, reducer},

      locale 
      
    }