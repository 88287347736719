const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',

  SETCONSEILLER:'SETCONSEILLER',
  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
  login: (token = false) => ({
    type: actions.LOGIN_REQUEST,
    payload: { token },
  }),

  loginSuccess: (token = false, user={}) => ({
    type: actions.LOGIN_SUCCESS,
    token , user 
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
