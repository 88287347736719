import defaultTheme from './default';
import customTheme from './custom';
import chartsTheme from "./charts"

const themes = {
  defaultTheme,
  customTheme,
  chartsTheme,
};

export const themeConfig = {
  topbar: 'defaultTheme',
  sidebar: 'defaultTheme',
  layout: 'defaultTheme',
  theme: 'defaultTheme',
};
export default themes;
