import { getName, getConseiller } from "#helpers/utility";
import gqlService from "#services/gql";
import { useQuery } from "@apollo/react-hooks";
import { Bar } from "@botndot/components";
import { formatDate, sameDay } from "@botndot/lib/helpers/dates";

var _ = require("lodash");

function HistoOffres({ type, critere, typeContrat, ...props }) {
  const conseiller = getConseiller();

  const { data, loading } = useQuery(gqlService.GET_LIST_LIGHT, {
    variables: {  sort: "createdAt" },
  });

  const MS =_.get(data, getName("serviceNameP"), []);

  let histo = [];

  if (MS.length) {
    let date = MS[0].createdAt;
    histo = [{ value: 0, count: 0, countS: 0, cumulValue: 0, cumulCount: 0, cumulCounts: 0, date: formatDate(date) }];

    MS.forEach((offre) => {
      let total;
      let souscription = {};
      let countS;
      if (typeContrat) {
        souscription = offre.souscriptions.find((s) => s.typeContrat == typeContrat);
        if (souscription) {
          total = souscription[critere];
          countS = 1;
        } else {
          (countS = 0), (total = 0);
        }
      } else {
        countS = offre.souscriptions.length;

        total = _.reduce(
          offre.souscriptions,
          function(sum, s) {
            return sum + s[critere];
          },
          0
        );
      }

      let last = _.last(histo);

      if (sameDay(date, offre.createdAt)) {
        last.count++;
        last.countS += countS;
        last.value += total;
        last.cumulValue += total;
        last.cumulCounts += countS;
        last.cumulCount++;
      } else {
        date = offre.createdAt;

        histo.push({ value: total, count: 1, countS, cumulValue: last.cumulValue + total, cumulCount: last.cumulCount + 1, cumulCounts: last.cumulCounts + countS, date: formatDate(date) });
      }
    });
  }

  return (
    <Bar
      xAxis={{ name: "date", data: histo.map((h) => h.date) }}
      yAxis={{ axisLabel: { formatter: "{value} " } }}
      series={[
        {
          type: "bar",

          name: "Valeur",
          data: histo.map((i) => i[type]),
        },
        {
          name: "Cumul",
          type: "line",
          data: histo.map((i) => i["cumul" + _.capitalize(type)]),
        },
      ]}
    />
  );
}

HistoOffres.defaultProps = {
  critere: "montantBrut",
  type: "value",
};

export default HistoOffres;
