import styled from "styled-components"
import {palette} from "styled-theme"


export const   MenuWrapper= styled.div`
height:100%;
>.ant-menu{
    height:100%;
    width:100%;
    background: ${palette('menuColor', 0)};
    .ant-menu-inline{

     background: ${palette('menuColor', 1)};

    }

}



`



