import React ,{useState,useEffect} from 'react'
import {displayName} from "@botndot/lib/helpers/text"
import {getClient,getDispatch} from "#helpers/utility"
import {getIcon} from "@botndot/assets/Icons"
import   {Menu}  from "@botndot/ui"
import {ModuleWrapper } from './MenuStyle'
export default function ClientMenu({ onClick,title,...props }) {
      
    
    const client=getClient()
    const dispatch = getDispatch();
    const [selectedClient, setselectedClient] = useState(_.get(client,"id"))

    useEffect(() => {
      console.log(selectedClient, '- Has changed');
      dispatch("moduleInit")
  },[selectedClient])

console.log(selectedClient)
  return (

  <Menu.Item    onClick={()=>{ onClick()}} {...props}>  {title} </Menu.Item >

  )
}
