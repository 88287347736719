import {Space, Avatar,List} from "@botndot/ui"
import {displayName} from '@botndot/lib/helpers/text'
import React from 'react'

//import {FinancialItemWrapper} from "./style"
export default function ListClients({item, onClick, onDoubleClick ,isSelected}) {

    return (
      
<List.Item key={item._id}  onClick={onClick}  onDoubleClick={onDoubleClick}  actions={[(<div>{item.company}</div>)]} >
                <List.Item.Meta
                  
              
                  title={displayName(item.prenom , item.nom)}
                />
                <div></div>
              </List.Item>
    )
}
