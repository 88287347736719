import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const FinancialItemWrapper  = styled.div`
background:${props => props.isSelected ? "#0000000d":"white"};

border-bottom:1px solid ${palette('grayscale',3)};



.ant-list-item-meta-title{    margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  max-width: 210px;}
 
 ` 