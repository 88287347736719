import React, { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { OrderListWrapper } from "./OrderList.style";
import {Button,Space, Radio} from "@botndot/ui"

const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    console.log(result)
    return result;

};


const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? "lightgreen" : "#f9f9f9",

    ...draggableStyle,
    top:isDragging ?"auto !important":"",
    left:isDragging ?"auto!important":"",
});

const getListStyle = (isDraggingOver) => ({
    padding: grid,
});


export default function OrderedList({initialItems,parentAction}) {
    const [items, setitems] = useState(initialItems)

const     onDragEnd= (result) => {
    if (!result.destination) {
        return;
    }
    const newItems = reorder(items, result.source.index, result.destination.index);
    setitems(newItems)
}



const onChangeSort=(item , value)=>{

    item.order=value

    setitems(_.clone(items))



}

const optionsSort=[

{value:"desc" , label:"-"},
{value:"x" , label:"x"},
{value:"asc" ,   label:"+"}
]

    return (
        <OrderListWrapper>
            
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {(items||[]).map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}>
                                      <Space> {item.content} 
                                      
                                      <Radio.Group
          options={optionsSort}
          onChange={(e)=>{onChangeSort(item, e.target.value)}}
          value={item.order}
          optionType="button"
        />
                                      </Space> 

                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>{" "}<Button type="primary" onClick={()=>{parentAction(items)}}>Sort</Button>
    </OrderListWrapper>
    )
}


