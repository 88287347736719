import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';
export default function Gauge({title , value , intervale,splitNumber , colorsType}) {
const theme=useContext(ThemeContext)
const colors=theme.palette[colorsType||"gaugeChart"]
let   option = {
  series: [{
      type: 'gauge',
      min: intervale[0],
      max:intervale[1],
      splitNumber:splitNumber,
      data: [{
        value: value
    }],
      axisLine: {
          lineStyle: {
              width: 15,
              color: [
                  [2/splitNumber, _.nth(colors,0)],
                  [4/splitNumber, _.nth(colors,1)],
                  [1, _.nth(colors,2)]
              ]
          }
      },
      pointer: {
          itemStyle: {
              color: 'auto'
          }
      },
      axisTick: {
          distance: -30,
          length: 8,
          lineStyle: {
              color: '#fff',
              width: 2
          }
      },
      splitLine: {
          distance: -45,
          length: 30,
          lineStyle: {
              color: '#f5f5f5',
              width: 2
          }
      },
      axisLabel: {
          color: 'auto',
          distance: 40,
          fontSize: 13
      },
      
      detail: {
        valueAnimation: true,
        formatter: '{value}',
        color: 'auto',
        offsetCenter: [0, 50], 
    },

  }]
};

if(title) option.title=  [ {text:title,x: 'center', y: '84%', textStyle:{color:"#333",fontSize:"12", fontWeight:500}},];

    return (
        <ReactEcharts
        option={option}
        notMerge={true}
        style={{height:  "100%", width: "100%"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}
