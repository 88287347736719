import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';
export default function Bar({title , data, sortable=true ,xAxis ,yAxis ,colors , colorsType}) {
const theme=useContext(ThemeContext)
const colorsRange=colors||theme.palette[colorsType||"BarChart"]

const   option = {
  color:colorsRange,
  title: {
    text: title,

    left: 'center'
  },
  tooltip: {
    trigger: 'item'
  },
  legend: {

    bottom: 'bottom'
  },
  series: [
    {
      name: 'Access From',
      type: 'pie',
      radius: '50%',
      data:data,
      emphasis: {
        itemStyle: {
          shadowBlur: 10,
          shadowOffsetX: 0,
          shadowColor: 'rgba(0, 0, 0, 0.5)'
        }
      }
    }
  ]
};
if(title) option.title=  [ {text:title,x: 'center', y: '0%', textStyle:{color:"#333",fontSize:"14"}},];

    return (
        <ReactEcharts
        option={option}
        notMerge={true}
        style={{height:  "100%", width: "100%"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}