import React from 'react';import { ApolloProvider } from '@apollo/react-hooks';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import {themes , AppLocale ,ConfigProvider} from "@botndot/core"

import client from "./services/apolloClient";
import { modules } from "@config";
export default function AppProvider({ children }) {
  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  modules.forEach(module=>{


    if(module.locale ){
      
      console.log(module.locale)
      AppLocale.merge(module.locale)}
  
    })
    
    console.log(AppLocale)
  const currentAppLocale = AppLocale[locale];
console.log(currentAppLocale.locale)

  return (
    <ApolloProvider client={client}>
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>
         
          {children}
          
          </ThemeProvider>
      </IntlProvider>
    </ConfigProvider>    </ApolloProvider>
  );
}
