import { getIcon } from "@botndot/assets/Icons";
import{ TopResizable, Fill,CenterType, LeftResizable, BottomResizable, RightResizable }from "react-spaces";
import {LayoutWrapper,IconWrapper} from "./Window.style"
import React , {useState,memo} from "react"
var _ = require("lodash")




export default (function Layout({initialLayout }) {


    const [layout, setlayout] = useState(initialLayout)


const renderControl=(key , title="" , rotation=false)=>{
    let container= _.get(layout, key )
    if(key=="fill.left.fill") container= _.get(layout, "fill.left" );
    if(key=="fill.right.fill") container= _.get(layout, "fill.right" );
    const toggleClose=()=>{


                 if(container.isHidden){  container.isHidden=false }
                 else{ container.isHidden=true}

        
                   setlayout({...layout})
    }


let path=key.split(".")
let icon=_.nth(path, -1)
if(icon=="fill") icon=_.nth(path, -2)

if(container.isHidden){
switch(icon){
    case "left" : icon="right";break ;
    case "right" : icon="left";break ;
    case "top" : icon="bottom";break ;
    case "bottom" : icon="top";break ;
    default : ;
}


}

let style={  
  }
if(rotation) style={
transformOrigin:7,
transform:" rotate(90deg)"}

    return (<>
{        !container.isCenter   && (  <IconWrapper onClick={toggleClose} className="icon">   
{getIcon("navigation."+icon+".#3366a2") } <h3 style={style}>{title}</h3> 

        </IconWrapper>)}</>
     )



}
    const renderComponents = (key) => {
        let container= _.get(layout, key )
        if(!container) return ""
        if (!container.isHidden) {
            let components = container.components
            return       (   <LayoutWrapper>          {renderControl(key)}
                <div className="LayoutContent">

                { !container.isHidden && components.map((component,i) => (<div style={{height:"100%"}}  key={i}> {component} </div>))}
                </div></LayoutWrapper>)
             
        }
        return   <div style={{}}> { renderControl(key , container.title)}</div> 

    }
const isHidden=(key)=>{

    let container= _.get(layout, key )
    return container.isHidden

}
    const getSize = (key) => {
        let container= _.get(layout, key )
        if (! container) return 0

        if(container.isHidden  )return 30
        
        return  _.get(layout, key + ".size", 0)

    }
    return (
        <Fill>
           <TopResizable  size={getSize("top")} >{renderComponents("top")}  </TopResizable>
           
           
            <Fill  >
{getSize("fill.left")&&(                <LeftResizable size={getSize("fill.left")} > 
                    
                    { isHidden("fill.left")?
                    
                    renderControl("fill.left" , "leftSide", true)
                    : ( <>  <TopResizable size={getSize("fill.left.top")} >{renderComponents("fill.left.top")}  </TopResizable>
                        <Fill   > {renderComponents("fill.left.fill")}   </Fill>
                         <BottomResizable size={getSize("fill.left.bottom")} >{renderComponents("fill.left.bottom")}  </BottomResizable> </>)}
                
                </LeftResizable>)}
                
                <Fill>
                    <TopResizable  size={getSize("fill.fill.top")}>{renderComponents("fill.fill.top")}  </TopResizable>
                    <Fill  > {renderComponents("fill.fill.fill")}   </Fill>
                    <BottomResizable   size={getSize("fill.fill.bottom")}> {renderComponents("fill.fill.bottom")} </BottomResizable>
                </Fill>
                
                

{getSize("fill.right")?(
                <RightResizable size={getSize("fill.right")}   > 
                { isHidden("fill.right")?
                    
                    renderControl("fill.right", "RightSide")
                    :  <><TopResizable size={getSize("fill.right.top")} >{renderComponents("fill.right.top")}  </TopResizable>
                        <Fill  > {renderComponents("fill.right.fill")}   </Fill>
                         <BottomResizable size={getSize("fill.right.bottom")} >{renderComponents("fill.right.bottom")}  </BottomResizable></>}
               </RightResizable>):(<></>)}

            </Fill>
           
           
            <BottomResizable size={getSize("bottom")}>{renderComponents("bottom")}</BottomResizable>
        </Fill>);

})
