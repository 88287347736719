
import WithDirection from '@botndot/lib/helpers/rtl';
import { Input } from 'antd';
import {
    InputGroupWrapper,
    InputSearchWrapper, InputWrapper,


    TextAreaWrapper
} from './Input.style';
const { Search, TextArea, Group } = Input;

const WDStyledInput = InputWrapper(Input);
const StyledInput = WithDirection(WDStyledInput);

const WDInputGroup = InputGroupWrapper(Group);
const InputGroup = WithDirection(WDInputGroup);

const WDInputSearch = InputSearchWrapper(Search);
const InputSearch = WithDirection(WDInputSearch);

const WDTextarea = TextAreaWrapper(TextArea);
const Textarea = WithDirection(WDTextarea);

export default Input;
export { InputSearch, InputGroup, Textarea ,StyledInput};

