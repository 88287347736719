import React from "react";
import { NavBarWrapper } from "./NavBar.style";
import { Row, Col } from "antd";
import Window from "../Window";

var _ = require("lodash");
var classNames = require("classnames");

const renderPanels = (children) => {
  const countChildren = React.Children.count(children);

  return React.Children.map(children, (child, i) => {
    return (
      <Col className={classNames({ "menuCenter ": i == 1 && countChildren == 3 }, { menuLast: i == countChildren - 1 && i != 0 })} flex={child.flex || 1}>
        {" "}
        {child}{" "}
      </Col>
    );
  });
};

export default function NavBar({ size, order, isMain, Left, Right, Fill }) {
  return (
    <NavBarWrapper className={classNames({ mainBar: isMain })} order={order} size={size}>
      {Left && <Window.Left size={Left.size}> {Left.component} </Window.Left>}
      {Fill && <Window.Fill centerContent={Window.CenterType.HorizontalVertical}> {Fill.component} </Window.Fill>}
      {Right && (
        <Window.Right className={""} size={Right.size}>
          {" "}
          {Right.component}{" "}
        </Window.Right>
      )}
    </NavBarWrapper>
  );
}

NavBar.prototype = {
  isMain: false,
};
