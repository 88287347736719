import { IoMdClose, IoMdRemove, IoMdMenu, IoMdMail, IoIosChatbubbles, IoIosNotifications, IoIosSearch } from "react-icons/io";

import { RiTableFill, RiMailSendLine } from "react-icons/ri";
import { IoSearch, IoAppsSharp, IoBrowsers, IoSendSharp, IoMdSend, IoHomeSharp } from "react-icons/io5";
import { MdDelete } from "react-icons/md";
import { AiOutlineFullscreen, AiOutlineFullscreenExit , AiOutlineFilePdf ,AiOutlineCalculator } from "react-icons/ai";
import { FaBars, FaSort, FaInbox, FaFolderOpen, FaRegFilePdf,FaUserAlt } from "react-icons/fa";
import { VscCircleFilled, VscTriangleLeft, VscTriangleRight, VscTriangleUp, VscTriangleDown } from "react-icons/vsc";
//Navigation VscTriangleLeft
export const NavClose = IoMdClose;
export const NavMinus = IoMdRemove;
export const NavFullIn = AiOutlineFullscreen;
export const NavFullOff = AiOutlineFullscreenExit;
export const SearchIcon = IoSearch; // Search icon
export const BrowserIcon = IoBrowsers; // Search icon
export const ListIcon = FaBars; // Search icon
export const TableIcon = RiTableFill;
export const GalleryIcon = IoAppsSharp;
export const SortIcon = FaSort;
export const TopbarMenuIcon = IoMdMenu; // Menu icon
export const TopbarMailIcon = IoMdMail;
export const TopbarMessageIcon = IoIosChatbubbles;
export const TopbarNotifyIcon = IoIosNotifications;
export const TopbarSearchIcon = IoIosSearch;
export const InboxIcon = FaInbox;
export const SentIcon = IoSendSharp;
export const BinIcon = MdDelete;
export const FolderIcon = FaFolderOpen;
export const TagIcon = VscCircleFilled;
export const LeftIcon = VscTriangleLeft;
export const RightIcon = VscTriangleRight;
export const UpIcon = VscTriangleUp;
export const DownIcon = VscTriangleDown;
export const PdfIcon = FaRegFilePdf;

VscTriangleLeft;
export default function getIcon(icon) {
  switch (icon) {
    case "close":
      return NavClose;
    case "minimize":
      return NavMinus;
    case "fullScreenIn":
      return NavFullIn;
    case "fullScreenOff":
      return NavFullOff;
    case "inbox":
      return InboxIcon;
    case "sent":
      return SentIcon;
    case "bin":
      return BinIcon;
    case "folder":
      return FolderIcon;
    case "tag":
      return TagIcon;
    case "left":
      return LeftIcon;
    case "right":
      return RightIcon;
    case "top":
      return UpIcon;
    case "bottom":
      return DownIcon;
    case "pdf":
      return PdfIcon;
    case "user":
      return FaUserAlt
  case "home" :
    return IoHomeSharp ;

  case "details" :
      return IoHomeSharp ;
  case "pdf" :
    return AiOutlineFilePdf;

case "calcul" :
  return AiOutlineCalculator ;
    default:
      return "";
  }
}
