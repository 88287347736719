import defaultTheme from './default';

export default {
  ...defaultTheme,
  palette: {
    primary: ['#f00'],
    secondary: ['#0f0'],
    background:['',"#1cbbfb"],
  },
};
