import React from 'react'
import {Table} from "@botndot/ui"
import {TableBrowserWrapper} from "./Table.style"
export default function InfinitTable({columns, dataSource , components}) {


    return (
        <TableBrowserWrapper>
<Table columns={columns}
        dataSource={dataSource}
        style={{height:"100%"}}
        scroll={{ x: true   , y:true}} 
        pagination={false}
        tableLayout ="fixed"
        bordered


        /></TableBrowserWrapper>
    )
}
