import React , {useContext} from 'react'
import ReactEcharts from "echarts-for-react";
import { ThemeContext } from 'styled-components';
import { transition } from '@botndot/lib/helpers/style_utils';
//series : [{data , title , symbol}]
export default function Line({title ,dates, series, colorsType}) {
const theme=useContext(ThemeContext)
const colors=theme.palette[colorsType||"serieChart"]
const   option = {
    tooltip: {
        trigger: 'axis'  
    },
    grid: {
        left: "0%",
        right: "5%",
        bottom: "50",
        top: "10%",
        containLabel: true
      },
    toolbox: {
        feature: {
            dataZoom: {  yAxisIndex: 'none'  },
            restore: {},
            saveAsImage: {}
        }
    },

    legend: {
        data: series.map(serie=>(serie.title)),
        top: "4%" ,
      },

    xAxis: {
        type: 'category',
        boundaryGap: false,
        data: dates,
        axisLabel: {
            show: true,
            interval: "auto", // {number}

            textStyle: {
              color: "#ccc",
              fontFamily: "sans-serif",
              fontSize: 10,
              fontStyle: "italic"
            }
          },
          axisLine: {
            // 轴线
            show: true,
            lineStyle: {
              color: "#f3f3f3",
              width: 2
            }
          },
          axisTick: {
            // 轴标记
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f9f9f9",
              type: "dashed",
              width: 1
            }
          }
    },
    yAxis: {
        type: 'value',
        position:'left',
        boundaryGap: [0, 0.1],
        scale:true,
        axisLabel: {
            show: true,
            interval: "auto", // {number}
            formatter: '{value} ',
            textStyle: {
              color: "#ccc",
              fontFamily: "sans-serif",
              fontSize: 10,
              fontStyle: "italic"
            }
          },
          axisTick: {
            // 轴标记
            show: true
          },
          axisLine: {
            // 轴线
            show: true,
            lineStyle: {
              color: "#f3f3f3",
  
              width: 2
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#f9f9f9",
              type: "dashed",
              width: 1
            }
          }
    },
    dataZoom: [
        {
          type: "inside",
          start: 0,
          end: 100
        },
        {
          start: 0,
          end: 100,
          handleIcon:
            "M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6,12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z",
          handleSize: "80%",
          handleStyle: {
            color: "#fff",
            shadowBlur: 3,
            shadowColor: "rgba(0, 0, 0, 0.6)",
            shadowOffsetX: 2,
            shadowOffsetY: 2
          }
        }
      ],
    series: series.map((serie,i)=>{
      
        return {
            name: serie.title,
            type: 'line',
            symbol: serie.symbole||"",
            sampling: 'lttb',
            itemStyle: {
                color: colors.series[i%colors.series.length]
            },
            areaStyle:serie.isAreaStyle?  {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color:  colors.gradient[0]
                }, {
                    offset: 1,
                    color: colors.gradient[1]
                }])
            }:{},
            lineStyle: {
                normal: {
                  width:1,
                  color:colors.series[i%colors.series.length]
                }
              },

            data: serie.data
        }


    })  
};
if(title) option.title=     {  left: 'center', text: title, }

    return (
        <ReactEcharts
        option={option}
        notMerge={true}
        style={{height:  "100%", width: "100%" , overFlow:"hidden"}}
        loadingOption={{
          text: "Loading",
          textColor: "#333" , 

        }}
        lazyUpdate={true}
      />
    )
}