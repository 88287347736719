import { Avatar, Badge } from "@botndot/ui";
//import { mailTags as tags } from "config";
import { timeDifference } from "@botndot/lib/helpers/utility";
import React from "react";
import { DragPreviewImage, useDrag } from "react-dnd";

export default function Draggable({ type,item ,children }) {
 
    const [{ opacity }, drag, preview] = useDrag({
        type: type,
        item,
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.4 : 1,
        }),
    });

    
    return (
        <>
            <DragPreviewImage connect={preview} src={"https://image.flaticon.com/icons/png/32/2938/2938080.png"} />
            <div ref={drag} style={{ opacity }}>
{children}
            </div>
        </>
    );
}
