import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const IconTextWrapper  = styled.li`

{
    padding: 10px;
    display: flex;
      .iconLeft{
        float: left;
        width: 80%;
    }
    
    }
      .iconRight{
          float:right;
        width:20%;
        text-align:right;

.ant-badge-count{background : ${palette("menuColor",1)} ;color:${palette('text',0)}  ; border:none;}
        }

 }
 
 
 
 ` 