import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const LayoutWrapper  = styled.div`
 .icon{
    svg{opacity:0} 
    position:absolute;     background: white;
    z-index: 1;
    top: 8px;}
 :hover{.icon{svg{opacity:1;}}}
 background: transparent;
 height: 100%;
 padding: 4px;
 .LayoutContent{
     
    background-color: #fff;
   
    -webkit-box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);

    border-radius: .25rem;
    height:100%;

}
 ` 


 export const IconWrapper=styled.div`

 display: block;
 position: relative;

 padding: 13px 0px 13px 6px;

svg{float:left}
 h3{     font: bold 12px Sans-Serif;
    -webkit-letter-spacing: 2px;
    -moz-letter-spacing: 2px;
    -ms-letter-spacing: 2px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #3366a2;
    float: left;
padding-left:5px;}
 
 `
