import { name ,moduleName} from "#config";
import actions from "../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export function getState() {
  return useSelector((state) => state[getName("reduxName")]);
}

export function getUser() {
  return useSelector((state) => state.Auth.user||{});
}

export function getConseiller() {

  return useSelector((state) => state.Auth.conseiller||{});

}
export function getClient() {


return  useSelector((state) => state.Clients.selectedItem||{});
}

export function getAction(actionName) {
  return actions[actionName]||actionName;
}

export function getName(type) {
  switch (type) {
    case "moduleName":
      return _.capitalize(name) + "s";
    case "reduxName":
      return _.capitalize(name) + "s";
    case "serviceName":
      return _.lowerCase(name);
    case "serviceNameP":
      return _.lowerCase(name) + "s";
    default:
      return name;
  }
}



export function getDispatch(){

   const dispatch=useDispatch()
   return (action,params)=>{
    dispatch(
      getAction(action)(params)
    );

   }

}


export function translate(text){

  return  moduleName+"."+text
 
 
 }