import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const WrapperStyle = styled.div`
 overflow: auto;
 height: 100%;

 border-radius: 5px;
 .ant-card{
  overflow: auto;
  height: 100%;
  border-radius: 5px;
  border: none;
  overflow:hidden;
  >.ant-card-head{
    h3{     font: bold 12px Sans-Serif;
      -webkit-letter-spacing: 2px;
      -moz-letter-spacing: 2px;
      -ms-letter-spacing: 2px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color:${palette('text',0)};
      float: left;
}
min-height:40px;
height:40px;
border:none;
>.ant-card-head-title {padding: 7px 0;}}

>.ant-card-body{

  height:calc(100% - 40px);
  overflow:auto;
  padding-top: 0px;


}
  }




}
 ` 