import styled from "styled-components";
import { palette } from 'styled-theme';


export const WindowWrapper = styled.div`
    contain: strict;
    height: 100%;
    overflow: hidden;

    cursor: default;
    background-color: ${palette('background',1)};
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    border: none !important;

    box-shadow: rgb(0 0 0 / 31%) 0px 0px 1px, rgb(0 0 0 / 18%) 0px 0px 5px, rgb(0 0 0 / 30%) 0px 8px 50px;
    .mainBar{text-align:center; 
        background-color: ${palette("primary",0)};
    }
`;


export const ButtonGroup = styled.div`
    user-select: none;
    cursor: default;
    display: flex;
    width: 61px;
    :hover svg{
      display: block;
  }
  position: absolute;
  right: 10px;
  top: 5px;


`;
export const SingleButton = styled.a`
    -webkit-app-region: no-drag;
    border-radius: 50%;
    margin-top: 1px;
    margin-left: 7px;
    margin-right: 1px;
    line-height: 0;
    width: 13px;
    padding:2px;
    height: 13px;
cursor:initial;
    font-size: 9px;
    display: inline-table;
    color: rgb(0 0 0) !important;
    font-weight: bold;
    > svg {
        display: none;
    }

    &.minus {
        background-color: #facc6b;
        background-image: linear-gradient(315deg, #facc6b 0%, #fabc3c 74%);
    }

    &.close {
        background-color: #feae96;
        background-image: linear-gradient(315deg, #feae96 0%, #fe0944 74%);
    }
    &.fullscreen {
        background-color: #abe9cd;
        background-image: linear-gradient(315deg, #7bc5a4 0%, #3ace31 74%);
    }
`;
