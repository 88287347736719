
import saMessages from '../locales/it_IT.json';
import {addHeader} from "../helpers"
const saLang = {
  messages: {
    ...addHeader(saMessages),
  },

  locale: 'it-IT',
};
export default saLang;
