import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const FinancialItemWrapper  = styled.div`

.ant-list-item{  border:${props => props.isSelected ?    "  2px solid #5cb0c8 !important": "none"};}
.ant-list-item-meta-title{    margin-bottom: 4px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  line-height: 1.5715;
  max-width: 210px;}
 
 ` 