import {getIcon} from "@botndot/assets/Icons"
import {Table,Popconfirm,Checkbox} from "@botndot/ui"
import React ,{useState} from 'react'
import {TableWrapper} from "./Table.style"
const CheckboxGroup = Checkbox.Group;


export default function defaultTable({columns, ...props}) {
    const [selectedColumns, setselectedColumns] = useState(columns)
    var cols=selectedColumns.map(col=>(col.dataIndex))
    const columnsOptions=_.tail(columns).map(col=>({label:col.title ,value:col.dataIndex }))
    const onChangeSelect=(checkedValues)=>{   cols=checkedValues  }
    const confirm=()=>{setselectedColumns(columns.filter((col,i)=>(i==0 || cols.indexOf(col.dataIndex)!==-1 ))) }

   
    columns[0].title=(
        <Popconfirm
        placement="leftBottom"
        title={

            <CheckboxGroup options={columnsOptions} 
                      defaultValue={cols} onChange={onChangeSelect} />}       
        onConfirm={confirm}
        okText="Yes"
        cancelText="No"
      >
{ getIcon('actions.filter')}
      </Popconfirm>
       )
    return (
        <TableWrapper>
            <Table  columns={selectedColumns} { ...props} />
        </TableWrapper>
    )
}


