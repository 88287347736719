import actions from "./actions";
var _ = require("lodash");
import {getConseiller } from "#helpers/utility";

const initState = {
    selectedModule: -1,

    filterAttr: { bucket: "Inbox" },
    selectedScreen: { screen: "List", selectedView: { view: "Gallery", subView: null }, props: {} },
    selectedItem: null,
    selectedItems: [],

    SingleState: {
        selectedView: { view: "Main", subView: null },
    },

    ListState: {
        selectedView: { view: "Gallery", subView: null },
        isSearch: false,
        searchParams: {
            limit: 10,
            offset: 0,
            search: null,
            s: null,
            filters: [],
            folder: null,
            refresh: null,
            sort :"updatedAt:desc"
        },


  
    },
};

export default function ModuleReducer(state = initState, action) {
    switch (action.type) {
        case actions.MODULE_INIT:
            return {
                ...initState,
            };

        case actions.SCREEN_SET_SELECTED:
            state.selectedScreen = action.screen;
            if (action.screen.selectedView) state[state.selectedScreen.screen + "State"].selectedView = action.selectedView;
            if (state.selectedScreen.screen == "List") state.selectedItem = null;

            return {
                ...state,
            };

        case actions.LIST_SWITCH_VIEW:
            state.ListState.selectedView = action.view;

            return {
                ...state,
            };

        case actions.LIST_TOGGLE_SEARCH:
            state.ListState.isSearch = !state.ListState.isSearch;

            return {
                ...state,
            };
        case actions.SET_SEARCH_PARAMS:
            state.ListState.searchParams = action.searchParams;

            return {
                ...state,
            };
        case actions.SET_SORT_PARAMS:
            state.ListState.sortParams = action.sortParams;

            return {
                ...state,
            };
        case actions.SET_SELECTED_ITEM:
            state.selectedItem = action.selectedItem;
            state.selectedItems = [action.selectedItem];
            return {
                ...state,
            };
        case actions.SET_SELECTED_ITEMS:
            if (_.find(state.selectedItems, { _id: action.M._id })) {
                state.selectedItems = _.filter(state.selectedItems, (item) => item._id != action.M._id);
            } else {
                state.selectedItems = _.concat(state.selectedItems, action.M);
            }
            return {
                ...state,
            };
        case actions.RESET_SELECTED_ITEMS:
            state.selectedItems = [];
            return {
                ...state,
            };
        case actions.SINGLE_SWITCH_VIEW:
            state.SingleState.selectedView = action.view;

            return {
                ...state,
            };
        case actions.SINGLE_SWITCH_SUBVIEW:
            state.SingleState.selectedSubView = action.subView;

            return {
                ...state,
            };
        default:
            return state;
    }
}
