import React,{useState} from 'react'
import {gqlService} from "@botndot/core"
import { useQuery } from '@apollo/react-hooks';
import {Spin, Select,Input,Button} from "@botndot/ui"
 import {getSetting} from "@botndot/lib/helpers/settings"
 import {SearchWrapper} from "./SearchSide.style"

 const {Option}=Select
 const {Group}=Input
export default function SearchSide({moduleName , setSearch , params}) {

    const { data, loading } = useQuery(gqlService.GET_SETTINGS, {
        variables:{filters:[{property:"type" , op:{eq:"module" }},{property:"nameSpace" , op:{eq:moduleName }}] }
      });

    const [searchType, setSearchType] = useState(params.search)
    const [searchString, setSearchString] = useState(params.s)
    const [filters, setFilters] = useState({})
     
    const handleChangeSearch=(value)=>{ 
       setSearchType(value)
    }
const handleReset=()=>{
    setSearchType("")
    setSearchString("")
    setFilters({})

}
    const handleChangeFilter=(field,value)=>{ 
        filters[field]=value
        setFilters(filters)
    }

    const handleSearch=()=>{
       let  newPArams=_.clone(params)
        newPArams.search=searchType
        newPArams.s=searchString
        newPArams.folder=""
        let newFilter=[]
        _.forIn(filters , (value, key)=>{newFilter.push( {property:key, op:{in:[value] } }) })
        newPArams.filters=newFilter
        setSearch(newPArams)
    }
      if(loading) return <Spin/>
      let modulsSettings=_.head(_.get(data,"settings",[]));
      
      let searchParams=getSetting(_.get(modulsSettings,"data",[]), "searchParams")
      console.log(searchParams)
      return ( 
        <SearchWrapper>
           <Group compact>
      <Select  value={searchType} style={{ width: '100%' }} 
      onChange={handleChangeSearch}
      >
   {  ( searchParams.search||[]).map(((param,k)=>(  <Option key={k} value={param.field}>{param.label}</Option>)))}
      </Select>
      <Input value={searchString} onChange={(e)=>{setSearchString(e.target.value)}} style={{ width: '100%' }} />

    </Group>

    {(searchParams.filters||[]).map((filter, i)=>(


<Select
key={i}
placeholder={filter.label}
onChange={(value)=>{handleChangeFilter(filter.field ,value )}}
style={{ width: '100%' }}
value={filters[filter.field ]}
>
{filter.values.map((item,j) => (
  <Option key={j} value={item}>
    {item}
  </Option>
))}
</Select>

        ))}
         <Button type="primary"   onClick={handleSearch}>Search</Button>
         <Button    onClick={handleReset}>Reset</Button>
        </SearchWrapper>
    )
}
