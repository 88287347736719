import styled from "styled-components" 
  import {palette} from "styled-theme"  

 export const WTableWrapper  = styled.div`

 width: 100%;
 display: grid;


.title{    padding: 10px 30px;
  background: -webkit-gradient(linear,left top,right top,to(#1cbbfb),from(#1cbbfb));
  border-radius: 5px;
  margin-bottom: 10px;
h1{    font-size: 36px;
  font-weight: 600;
  line-height: 1.2;
  color: #fff;
  text-align: center;}
  .subTitle{font-weight: bold; font-size: 14px; color: white; text-align: center;}
}
.infoWrapper{
width:100%;
border-bottom:1px solid #f3f3f3;
  .label{
    display: block;
    float: left;
    border-right: 1px solid #e9e9e9;
    width: 50%;
    background: #f5f5f5;
    height:100%;
    padding-left: 10px;
    word-wrap: break-word;}

    .contentInfo{   
      display: block;
      text-align: right;
      float: right;
      width: 50%;
      font-weight: 700;
      background:#eaedf2;
      padding-right: 10px;
        }}
      
 ` 