
import saMessages from '../locales/fr_FR.json';
import {addHeader} from "../helpers"
console.log(addHeader(saMessages));
const saLang = {
  
  messages: {
    ...addHeader(saMessages),
  },

  title:"offres",
  locale: 'fr-FR',
};
export default saLang;
