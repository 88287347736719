import styled from "styled-components" 
  import {palette} from "styled-theme"  
 export const MenuWrapper  = styled.div`{


    cursor: pointer;
    background: #f45249;
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    align-content: space-around;
    padding: 0px 16px;

 }` 