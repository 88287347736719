import { Rnd } from "react-rnd";

import React, { useState, Suspense } from "react";
import {
  NavClose,
  NavMinus,
  NavFullIn,
  NavFullOff,
} from "@botndot/assets/Icons/navigation";
import { WindowWrapper, SingleButton, ButtonGroup } from "./window.style";
import "./main.css";
import appActions from "../../../redux/app/actions";
import { useDispatch, useSelector } from "react-redux";
import { Window } from "@botndot/containers";
const { ViewPort, Top, CenterType, Fill } = Window;
const { openModule, closeModule, setFullScreen } = appActions;
let zcounter = 1;


export default React.memo(function Desktop({
  width,
  height,
  moduleWidth,
  moduleHeight,
}) {
  const dispatch = useDispatch();
  const { layouts, activeModules , current } = useSelector((state) => state.App);

  layouts.forEach((lay) => {
    lay.width = lay.width || moduleWidth || width / 2;
    lay.height = lay.height || moduleHeight || height / 2;
    lay.state = "fullScreen";
    if (lay.isNew) {
      lay.zIndex = ++zcounter;
      lay.isNew = false;
    }
  });

  const [selected, setselected] = useState("");
  const isActive = (lay) => {
    let active = _.indexOf(activeModules, lay.key) != -1;

    return active;
  };

  const renderModule = (lay,i) => {
   
    return (
      isActive(lay) && (
        <WindowWrapper  style={{display:current==lay.key? "block" :"none"}}  key={i}>
          <ViewPort
            onClick={() => {
              lay.zIndex = ++zcounter;
              setselected(zcounter);
            }}
          >
            {false && (
              <Top size={24} order={1} centerContent={CenterType.Vertical}>
                <div className="mainBar" style={{ height: 24 }}>
                  {lay.key}
                </div>
                <ButtonGroup>
                  <SingleButton className="fullscreen">
                    {lay.state == "fullScreen" ? (
                      <NavFullOff
                        onClick={() => {
                          toggleFullScreen(lay);
                        }}
                      />
                    ) : (
                      <NavFullIn
                        onClick={() => {
                          toggleFullScreen(lay);
                        }}
                      />
                    )}
                  </SingleButton>
                  <SingleButton className="minus">
                    <NavMinus
                      onClick={() => {
                        minimiseItem(lay);
                      }}
                    />
                  </SingleButton>
                  <SingleButton className="close">
                    <NavClose
                      onClick={() => {
                        onCloseItem(lay);
                      }}
                    />
                  </SingleButton>
                </ButtonGroup>
              </Top>
            )}

            <lay.component />
          </ViewPort>
        </WindowWrapper>
      )
    );
  };

  const onCloseItem = (lay) => {
    dispatch(closeModule(lay.key));
    dispatch(setFullScreen(false));
    setselected(++zcounter);
  };

  const minimiseItem = (lay) => {
    lay.state = "minimized";
    setselected(++zcounter);
    dispatch(setFullScreen(false));
  };

  const toggleFullScreen = (lay) => {
    if (lay.state == "fullScreen") {
      lay.state = "normal";
      dispatch(setFullScreen(false));
    } else {
      lay.state = "fullScreen";
      dispatch(setFullScreen(true));
    }

    setselected(++zcounter);
  };

  return (
    <Suspense fallback={<h1>Chargement du profils...</h1>}>
      <div
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        {layouts.map((lay) => renderModule(lay))}
      </div>
    </Suspense>
  );
});
