import styled from "styled-components";
import { palette } from "styled-theme";
import Window from "../Window";
let colorNav = "#323332";
export const NavBarWrapper = styled(Window.Top)`
    height:100%;
    padding: 4px 10px;
    border-bottom: 1px solid #f0f0f0;
    background: #f5f5f5;
.ant-radio-group{border-radius: 5px;}

    button{
        background: white;
        border-radius: 5px;
        border-color:${palette("navigation", 4)} !important;
        color:${colorNav} !important;
        background: white;
        border-radius: 5px;
    }P

    .ant-menu {
        background: transparent !important ;
        border:none;
        &.ant-menu-horizontal {
            line-height: 36px;
            padding-left: 20px;
        li {
            margin-left: 0px !important;
            border:none !important;
            }        
                 
        }
        
        }
    .ant-radio-button-wrapper{
        
        padding: 0px 5px;
        height: 27px;
                        color:${colorNav};
        border:1px solid  ${colorNav} !important;
        &:first-child{border-radius:5px 0 0 5px ; }
        &:last-child{border-radius:0px 5px  5px 0px; }
       &:not(:first-child) {border-left:none !important ;}
       color: :${colorNav};
       background:white;
       border-color:${palette("navigation", 4)} !important;
   &.ant-radio-button-wrapper-checked{  background: ${palette("navigation", 3)} ; color : ${palette("navigation", 4)} !important;  
    }
.ant-radio-button-wrapper::before{
    border-color:${palette("navigation", 4)} !important;
    color:${colorNav} !important;
    background: white;

   }
}
    &.mainBar {
        padding: 2px 10px;
        background:${palette("navigation", 0)};
        margin-top: 0px;
        box-shadow: rgb(40 42 49 / 16%) 0px 1px 2px 0px;
        background:linear-gradient(to bottom, rgb(249 249 249) 0%,rgb(249 249 249) 20%,rgb(245 245 245) 80%,rgb(243 243 243) 100%);
    

        .ant-menu {
li{           color:${palette("primary",0)};font-size: 13px;  

    /* font-weight: bold; */
    text-align: center;
   

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
 
    text-rendering: geometricPrecision;
    -webkit-font-smoothing: subpixel-antialiased;
     font-weight:600;
:hover,
.ant-menu-submenu-title:hover {
    color: ${palette("secondary", 0)} !important;}
}
}
.ant-radio-button-wrapper{
    background:transparent;
            color: ${palette("navigation", 2)} !important;
            border-color:${palette("navigation", 0)} !important;
       &.ant-radio-button-wrapper-checked{  background: ${palette("navigation", 0)} ; color : ${palette("navigation", 1)} !important;   }
        }
    }
    .menuCenter {
        text-align: center;
    }
    .menuLast {
        text-align: right;
    }

    button{background:transparent !important; border:none; }
    .ant-select-selector{    background: transparent !important;  border: none !important;}
  .ant-menu-root{background:transparent; line-height: 42px;}
`;
