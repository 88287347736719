import { List } from 'antd';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {InfinitListWrapper} from "./InfinitGallery.style"


export default function  InfinitList({dataSource, footer, renderItem, loadMore, loading, hasMore }){



    return (
     <InfinitListWrapper>
            <InfiniteScroll
                initialLoad={false}
                pageStart={0}
                loadMore={loadMore}
                hasMore={!loading && hasMore}
                useWindow={false}
            >
                <List
                 grid={{
gutter: 16, column: 4, 
                    xs: 1,
                    sm: 2,
                    md: 4,
                    lg:  4,
                    xl:  4,
                    xxl: 4,
                  }}
                    itemLayout="horizontal"
           
                    dataSource={dataSource}
                    footer={footer}
                    renderItem={renderItem}
                    loading={loading}>

                    {loading && hasMore && (
                        <div className="demo-loading-container">

                        </div>
                    )}
                </List>				

            </InfiniteScroll>
</InfinitListWrapper>
    )
}




