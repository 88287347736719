import { moduleName } from "../config";
const HEADER = moduleName.toUpperCase();
const actions = {
    MODULE_INIT: HEADER + "_MODULE_INIT",
    LIST_SWITCH_VIEW: HEADER + "_LIST_SWITCH_VIEW",
    LIST_TOGGLE_SEARCH: HEADER + "_LIST_TOGGLE_SEARCH",
    SET_SEARCH_PARAMS: HEADER + "_SET_SEARCH_PARAMS",
    SET_SELECTED_ITEM: HEADER + "_SET_SELECTED_ITEM",
    SET_SELECTED_ITEMS: HEADER + "_SET_SELECTED_ITEMS",
    RESET_SELECTED_ITEMS: HEADER + "_RESET_SELECTED_ITEMS",
    SCREEN_SET_SELECTED: HEADER + "_SCREEN_SET_SELECTED",
    SINGLE_SWITCH_VIEW: HEADER + "_SINGLE_SWITCH_VIEW",
    SINGLE_SWITCH_SUBVIEW: HEADER + "_SINGLE_SWITCH_SUBVIEW",
    moduleInit: () => ({
        type: actions.MODULE_INIT,
    }),

    setSelectedScreen: (screen) => ({
        type: actions.SCREEN_SET_SELECTED,
        screen,
    }),

    listSwitchView: (view) => ({
        type: actions.LIST_SWITCH_VIEW,
        view,
    }),
    listToggleSearch: (view) => ({
        type: actions.LIST_TOGGLE_SEARCH,
        view,
    }),
    setSearchParams: (searchParams) => ({
        type: actions.SET_SEARCH_PARAMS,
        searchParams,
    }),
    setSortParams: (sortParams) => ({
      type: actions.SET_SORT_PARAMS,
      sortParams,
  }),
    setSelectedItem: (selectedItem) => ({
        type: actions.SET_SELECTED_ITEM,
        selectedItem,
    }),

    setSelectedItems: (M) => ({
        type: actions.SET_SELECTED_ITEMS,
        M,
    }),

    resetSelectedItems: () => ({
        type: actions.RESET_SELECTED_ITEMS,
    }),
    singleSwitchView: (view) => ({
        type: actions.SINGLE_SWITCH_VIEW,
        view,
    }),
    singleSwitchSubView: (subView) => ({
        type: actions.SINGLE_SWITCH_SUBVIEW,
        subView,
    }),
    setConseiller: (conseiller) => {

  console.log(conseiller)
        return {
        type: "SETCONSEILLER",
      conseiller ,
      }},
    
};
export default actions;
