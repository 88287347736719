import { getDispatch, getName, getState, getConseiller } from '#helpers/utility';
import gqlService from '#services/gql';
import ListClientsItem from '#ui/ListClientsItem';
import { useQuery } from '@apollo/react-hooks';
import { getIcon } from '@botndot/assets/Icons';
import { InfinitList } from '@botndot/components';
import { Dropdown } from '@botndot/ui';
import { displayName } from '@botndot/lib/helpers/text';
var _ = require('lodash');

export default function ListClients({ Menu }) {
  const dispatch = getDispatch();

  const { selectedItem } = getState();

  const conseiller = getConseiller();
  const { data, loading } = useQuery(gqlService.GET_LIST, {
    variables: { where: { conseiller_eq: conseiller._id } },
    fetchPolicy: 'network-only',
  });

  const MS = _.get(data, getName('serviceNameP'), []);

  const renderItem = (M) => {
    return (
      <ListClientsItem
        item={M}
        onClick={() => {
          dispatch('setSelectedItem', M);
        }}
      />
    );
  };

  const loadMore = () => {};
  const Footer = () => <div></div>;
  console.log(selectedItem);

  return (
    <Dropdown
      style={{ width: '100%' }}
      overlay={
        <InfinitList
          style={{ width: '100%' }}
          dataSource={MS}
          renderItem={renderItem}
          loading={loading}
          footer={<Footer />}
          loadMore={loadMore}
          hasMore={true}
        />
      }
    >
      <div style={{ width: '100%', fontWeight: 600 }}>
        {getIcon('navigation.user')}{' '}
        {selectedItem
          ? displayName(selectedItem.prenom, selectedItem.nom)
          : 'Choix  Clients'}
        <div style={{ float: 'right', paddingRight: 10 }}>
          {getIcon('navigation.bottom')}
        </div>
      </div>
    </Dropdown>
  );
}
